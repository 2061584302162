import type { PaginateResult } from "mongoose"
import merge from "lodash-es/merge"

export interface Approval {
  approved: boolean
  date: string
  user: string
  remark?: string
}

export interface PriceListArticle {
  _id: string
  description: string
  articleNumber?: string
  price: number
}

export interface PriceList {
  _id: string
  description: string
  articleCategory: string
  fileName?: string
  startAt?: string
  endAt?: string
  approval?: Approval
  articles?: Array<PriceListArticle>
  useForInspections?: boolean
  useForMalfunctions?: boolean
  useForActions?: boolean
  useHours?: boolean
  manuallyEnded?: boolean
  _status: PriceListStatus
}

export interface Contract {
  _id: string
  organization: string
  supplier?: string
  description: string
  responsibleUser?: { _id: string; description: string }
  referenceNumber?: string
  startAt?: string
  endAt?: string
  includeZeroCosts: boolean
  priceLists?: Array<PriceList>
  assets: Array<{ _id: string; description: string, key: string }>
  _status: ContractStatus
}

export enum ContractStatus {
  AWAITING_START_DATE = "AWAITING_START_DATE",
  EXPIRED = "EXPIRED",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  NO_ACTIVE_PRICE_LISTS = "NO_ACTIVE_PRICE_LISTS",
  PARTIALLY_APPROVED = "PARTIALLY_APPROVED",
  APPROVED = "APPROVED",
  MANUALLY_ENDED = "MANUALLY_ENDED",
  DENIED = "DENIED",
}

export enum PriceListStatus {
  AWAITING_START_DATE = "AWAITING_START_DATE",
  EXPIRED = "EXPIRED",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  DENIED = "DENIED",
  APPROVED = "APPROVED",
  MANUALLY_ENDED = "MANUALLY_ENDED",
}

export interface ContractUpdate {
  description?: string | undefined
  responsibleUser?: { _id: string; description: string }
  referenceNumber?: string
  startAt?: string
  endAt?: string
  includeZeroCosts: boolean
}

export interface ContractCreate extends ContractUpdate {
  /** Can be used when a supplier creates a contract from his organization for another organization.\
   * If left empty the current organization will be used. */
  organization?: string
  supplier?: string
}

export const useContractStore = defineStore("contract", () => {
  const baseUrl = "/contracts"

  const getByPage = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<Contract>> => {
    const defaultFilter = {
      select: {
        "priceLists.startAt": 1,
        "priceLists.endAt": 1,
        "priceLists.approval": 1,
        "priceLists.manuallyEnded": 1,
      },
    }
    const combined = merge(defaultFilter, queryParameters)
    const query = getUrlSearchParams(combined)

    const { data } = await useSamApi<PaginateResult<Contract>>(`${ baseUrl }?${ query }`)
    return data
  }

  const getById = async (id: string): Promise<Contract> => {
    const { data } =  await useSamApi<Contract>(`${ baseUrl }/${ id }`)
    return data
  }

  const create = async (body: ContractCreate): Promise<Contract> => {
    const { data } = await useSamApi<Contract>(baseUrl, {
      method: "POST",
      body: JSON.stringify(body)
    })

    return data
  }

  const update = async (id: string, body: ContractUpdate): Promise<Contract> => {
    const { data } = await useSamApi<Contract>(`${ baseUrl }/${ id }`, {
      method: "PUT",
      body: JSON.stringify(body)
    })

    return data
  }

  const deleteById = async (id: string): Promise<Contract> => {
    const { data } = await useSamApi<Contract>(`${ baseUrl }/${ id }`, { method: "DELETE" })

    return data
  }

  const createPriceList = async (id: string, priceList: Partial<PriceList>) => {
    const { data } = await useSamApi<PriceList>(`${ baseUrl }/${ id }/price-lists`, {
      method: "POST",
      body: JSON.stringify(priceList)
    })

    return data
  }

  const updatePriceList = async (id: string, priceListId: string, priceList: Partial<PriceList>) => {
    const { _status, ...rest } = priceList
    const { data } = await useSamApi<PriceList>(`${ baseUrl }/${ id }/price-lists/${priceListId}`, {
      method: "PUT",
      body: JSON.stringify(rest),
    })

    return data
  }

  const endContract = async (id: string) => {
    const { data } = await useSamApi<Contract>(`${ baseUrl }/${ id }/end`, {
      method: "PATCH",
    })

    return data
  }

  const deletePriceList = async (id: string, priceListId: string) => {
    const { data } = await useSamApi<PriceList>(`${ baseUrl }/${ id }/price-lists/${priceListId}`, {
      method: "DELETE"
    })

    return data
  }

  const approvePriceList = async (id: string, priceListId: string) => {
    const { data } = await useSamApi<PriceList>(`${ baseUrl }/${ id }/price-lists/${priceListId}/approve`, {
      method: "POST"
    })
    return data
  }

  const denyPriceList = async (id: string, priceListId: string, remark: string) => {
    const { data } = await useSamApi<PriceList>(`${ baseUrl }/${ id }/price-lists/${priceListId}/deny`, {
      method: "POST",
      body: JSON.stringify({ remark }),
    })

    return data
  }

  const updateAssets = async (id: string, assets: Array<string>): Promise<Contract> => {
    const { data } = await useSamApi<Contract>(`${ baseUrl }/${ id }/assets`, {
      method: "PUT",
      body: JSON.stringify(assets)
    })

    return data
  }

  return {
    getByPage,
    getById,

    create,
    update,
    updateAssets,
    deleteById,

    createPriceList,
    updatePriceList,
    endContract,
    deletePriceList,
    approvePriceList,
    denyPriceList,
  }
})