import type { PaginateResult, QueryOptions } from "mongoose"
import type { ActionData } from "./action.store"
import type { Asset, LookupAsset, Location as AssetLocation } from "./asset.store"
import type { FileData } from "./file.store"
import type { InspectionData, OverviewInspection } from "./inspection.store"
import type { MalfunctionData } from "./malfunction.store"
import type { StatusDescription } from "./status.store"
import type { GeoPoint } from "./types"
import omit from "lodash-es/omit"
import merge from "lodash-es/merge"

export interface TotalPerMonthResult {
  status: StatusDescription
  data: Array<number>
}

export interface TotalPerStatusResult {
  status: {
    description: StatusDescription
    group: string
    sortOrder: number
  }
  count: number
}

export interface TicketArticle {
  _id: string
  articleId?: string
  category?: string
  articleNumber?: string
  description?: string
  amount: number
  price: number | undefined
  totalPrice: number | undefined
  /** Time work started */
  startAt?: string
  /** Time work ended */
  endAt?: string
}

export interface TicketArticleWithKeys extends TicketArticle {
  ticketKeys: string
}

export enum TicketType {
  INSPECTION = "Inspection",
  ACTION = "Action",
  MALFUNCTION = "Malfunction",
}

export interface TicketAsset {
  _id: string
  key: string
  description: string
  category: Pick<AssetCategory, "_id" | "description" | "marker">
  remark?: string
  riskProfile?: string
  template: string
  tags: Array<Pick<AssetTag, "_id" | "description" | "color">>
  location?: AssetLocation
}

export type Ticket = {
  _id: string
  asset: TicketAsset
  organization: string
  users: Array<{ _id: string; description: string }>
  supplier?: { _id: string; description: string }
  contract?: { _id: string; description: string }
  status: StatusDescription
  key: number
  oldKey?: string
  description: string
  remark?: string
  date?: string
  startAt?: string
  endAt?: string
  priority: string
  gps?: GeoPoint
  files: Array<string>
  costsMaterial: number | undefined
  costsLabor: number | undefined
  costsTotal: number | undefined
  laborHours: number
  articles: Array<TicketArticle>
  tags?: Array<string>
  updatedAt: string
  createdAt: string
  createdBy: string
  invoicePeriod?: string
} & (
  | {
      type: TicketType.MALFUNCTION
      data: MalfunctionData
    }
  | {
      type: TicketType.INSPECTION
      data: InspectionData
    }
  | {
      type: TicketType.ACTION
      data: ActionData
    }
  )

export type ListTicket = {
  _id: string
  articles: Array<TicketArticle>
  asset: TicketAsset
  contract?: { _id: string; description: string }
  createdAt: string
  createdBy: string
  date?: string
  description: string
  endAt?: string
  key: number
  oldKey?: string
  organization: string
  priority: string
  remark?: string
  startAt?: string
  status: StatusDescription
  supplier?: { _id: string; description: string }
  type: string
  updatedAt: string
  users: Array<{ _id: string; description: string }>
  tags?: Array<string>
  invoicePeriod?: string
} & (
  | {
      type: TicketType.MALFUNCTION
      data: MalfunctionData
    }
  | {
      type: TicketType.INSPECTION
      data: InspectionData
    }
  | {
      type: TicketType.ACTION
      data: ActionData
    }
  )

export type ListTicketWithActionCount = ListTicket & {
  actionsCoupledCount?: number
  linkedFilesCount?: number
}

export type PopulatedListTicket = Omit<ListTicket, "asset"> & {
  asset?: LookupAsset
}

export type ArticleListTicket = Omit<ListTicket, "articles"> & {
  article: TicketArticle
}

export type OverviewTicket = ListTicket & {
  costsLabor: number
  costsMaterial: number
  costsTotal: number
  laborHours: number
}

export type PopulatedOverviewTicket = Omit<OverviewTicket, "asset"> & {
  asset?: Asset
}

export type TicketBatchPayloadData = Pick<Partial<Ticket>, "users" | "supplier" | "description" | "date" | "status" | "remark" | "contract" | "invoicePeriod" | "organization" | "type" | "tags">

export interface TicketBatchPayload {
  ticketIds: Array<string>
  ticketData: TicketBatchPayloadData
}

export interface CreateTicketArticlePayload {
  articleId?: string
  category?: string
  articleNumber?: string
  description?: string
  amount?: number
  price?: number
  date?: string
  startAt?: string
  endAt?: string
}

export type CreateTicketInspectionData = Pick<InspectionData, "inspectionType">

export interface CreateTicketActionData {
  /** Asset component group id */
  componentGroup?: string
  /** Asset component id */
  component?: string
  /** Remark of the mechanic on the settlement of the work */
  settlement?: string
}

export interface CreateTicketMalfunctionData {
  /** Asset component group id */
  componentGroup?: string
  /** Asset component id */
  component?: string
  /** Cause of the malfunction (id) */
  cause?: string
  /** Solution for the malfunction (id) */
  solution?: string
  /** Remark of the mechanic on the settlement of the work */
  settlement?: string
}

export type CreateTicketPayload = {
  /** Asset id */
  asset: string
  /** User ids that are assigned to the ticket */
  users?: Array<string>
  /** The creator's organization id is used for the supplier if left empty */
  supplier?: string
  /** Contract id */
  contract?: string
  description: string
  remark?: string
  date?: string
  startAt?: string
  endAt?: string
  // articles: Array<CreateTicketArticlePayload>
  invoicePeriod?: string
} & ({
  type: TicketType.INSPECTION
  data: CreateTicketInspectionData
} | {
  type: TicketType.ACTION
  data?: CreateTicketActionData
} | {
  type: TicketType.MALFUNCTION
  data?: CreateTicketMalfunctionData
})

export type CreateBatchTicketPayload = Omit<CreateTicketPayload, "asset">
export type MalfunctionTicket = Extract<Ticket, { type: TicketType.MALFUNCTION }>
export type ActionTicket = Extract<Ticket, { type: TicketType.ACTION }>
export type InspectionTicket = Extract<Ticket, { type: TicketType.INSPECTION }>

export type CreateTicketResult<T extends { type: TicketType }> =
  T["type"] extends TicketType.INSPECTION ? InspectionTicket :
  T["type"] extends TicketType.ACTION ? ActionTicket :
  MalfunctionTicket

export interface TicketCostPdf {
  description?: string
  invoicePeriod?: string
  remark?: string
}

export const useTicketStore = defineStore("tickets", () => {
  /** @deprecated */
  const tickets = ref<Array<Ticket>>([])
  /** @deprecated */
  const assetsLookup = ref<Array<LookupAsset>>([])
  /** @deprecated */
  const listTickets = ref<Array<ListTicket>>([])
  /** @deprecated */
  const inspectionAnalysisTickets = ref<Array<OverviewInspection>>([])

  const getTicketCount = async (queryParameters?: Record<string, unknown>) => {
    const searchParams = new URLSearchParams()
    if (queryParameters) {
      searchParams.append("query", JSON.stringify(queryParameters))
    }

    const { data } = await useSamApi<{ count: number }>(`/tickets/count?${searchParams}`)
    return data.count
  }

  const create = async (payload: Partial<Ticket>) => {
    return await useCrudMethods<Ticket>("/tickets", tickets).createItem(payload)
  }

  const get = async (queryParameters?: QueryOptions) => {
    return await useCrudMethods<Ticket>("/tickets", tickets).readItems(queryParameters)
  }

  const getById = async (id: string) => {
    return await useCrudMethods<Ticket>("/tickets", tickets).readItemById(id)
  }

  const getList = async (queryParameters?: QueryOptions) => {
    return await useCrudMethods<ListTicket>("/tickets/list", listTickets).readItems(queryParameters)
  }

  const getListByAssetId = async (assetId: string, queryParameters: QueryOptions) => {
    const query = getUrlSearchParams(queryParameters)
    const { data } = await useSamApi<Array<ListTicket>>(`/assets/${assetId}/tickets?${query}`)
    return data
  }

  const getListByPage = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<ListTicket>> => {
    const query = getUrlSearchParams(queryParameters)
    const { data } = await useSamApi<PaginateResult<ListTicket>>(`/tickets/list?${query}`)
    return data
  }

  const getListByPageWithActionCount = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<ListTicketWithActionCount>> => {
    const defaultFilter = { select: { "files": 1 } }
    const combined = merge(defaultFilter, queryParameters)
    const query = getUrlSearchParams(combined)

    const { data } = await useSamApi<PaginateResult<ListTicketWithActionCount>>(`/tickets/list-action-count?${query}`)
    return data
  }

  const getArticlesByPage = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<ArticleListTicket>> => {
    const defaultFilter = {
      query: {
        "articles": { $exists: true, $ne: [] },
      },
      select: {
        "articles": 1,
        "supplier._id": 1,
      },
    }

    const combined = merge(defaultFilter, queryParameters)
    const query = getUrlSearchParams(combined)

    const { data } = await useSamApi<PaginateResult<ListTicket>>(`/tickets/list?${query}`)

    const articles: Array<ArticleListTicket> = []

    // Create a new item for each article
    data.docs.forEach(ticket => {
      ticket.articles.forEach(article => {
        articles.push({
          ...omit(ticket, "articles"),
          article,
        })
      })
    })

    return {
      ...omit(data, "docs") as PaginateResult<ListTicket>,
      docs: articles
    }
  }

  const getListByArticleIds = async (articleIds: Array<string>) => {
    const { data } = await useSamApi<Array<ListTicket>>(`/tickets/list-by-article-ids`, {
      method: "POST",
      body: JSON.stringify({ articleIds }),
    })
    return data
  }

  const getArticlesPdf = async (articles:Array<string>, body: TicketCostPdf) => {
    const { data: pdf } = await useSamApi<Blob>(`/tickets/costs/articles-pdf`, {
      headers: { "Accept": "*/*" },
      method: "POST",
      body: JSON.stringify({
        articleIds: articles,
        ...body
      })
    })

    return URL.createObjectURL(pdf)
  }

  const getAssetsLookup = async (queryParameters: Record<string, unknown> = {}): Promise<Array<LookupAsset>> => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<Array<LookupAsset>>(`/tickets/assets-lookup?${query}`)

    assetsLookup.value = data
    return data
  }

  const getInspectionAnalysis = async (queryParameters?: QueryOptions) => {
    return await useCrudMethods<OverviewInspection>("/tickets/inspection-analysis", inspectionAnalysisTickets).readItems(queryParameters)
  }

  const getCountPerMonth = async (year: number, ticketType: TicketType) => {
    const searchParams = new URLSearchParams()
    searchParams.append("year", year.toString())
    searchParams.append("ticketType", ticketType.toString())

    const { data } = await useSamApi<Array<TotalPerMonthResult>>(`/tickets/count-per-month?${searchParams}`)
    return data
  }

  const getCountPerStatus = async (queryParameters: Record<string, unknown> = {}) => {
    const query = getUrlSearchParams(queryParameters)
    const { data } = await useSamApi<Array<TotalPerStatusResult>>(`/tickets/count-per-status?${query}`)
    return data
  }

  const update = async (id: string, ticket: Partial<Ticket>) => {
    // Remove asset from inspection to prevent error
    const { asset, ...copy } = ticket
    return await useCrudMethods<Ticket>("/tickets", tickets).updateItem(id, copy)
  }

  const addFile = async (id: string, fileId: string) => {
    const { data } = await useSamApi<Ticket>(`/tickets/${id}/files/${fileId}`, { method: "POST" })

    const existingInspection = tickets.value.find((item) => item._id === id)
    if (existingInspection) {
      Object.assign(existingInspection, data)
      return data
    }

    tickets.value.push(data)

    return data
  }

  const deleteFile = async (id: string, fileId: string) => {
    const { data } = await useSamApi<Ticket>(`/tickets/${id}/files/${fileId}`, { method: "DELETE" })

    const existingInspection = tickets.value.find((item) => item._id === id)
    if (existingInspection) {
      Object.assign(existingInspection, data)
      return data
    }

    tickets.value.push(data)

    return data
  }

  const getFiles = async (id: string) => {
    const { data } = await useSamApi<Array<FileData>>(`/tickets/${id}/files`)
    return data
  }

  const batchHasLinkedData = async (ticketIds: Array<string>) => {
    const { data } = await useSamApi<boolean>(`/tickets/batch-has-linked-data`, { method: "POST", body: JSON.stringify({ ticketIds }) })
    return data
  }

  const batchEdit = async (ticketIds: Array<string>, ticketData: Partial<Ticket>, propsForceEmpty?: Array<string>) => {
    const { data } = await useSamApi<Array<Ticket>>(`/tickets/batch-edit`, { method: "POST", body: JSON.stringify({ ticketIds, ticketData, propsForceEmpty })})
    return data
}

  const batchCreate = async (assetIds: Array<string>, ticketData: CreateBatchTicketPayload) => {
    const { data } = await useSamApi<Array<string>>(`/tickets/batch-create`, { method: "POST", body: JSON.stringify({ assetIds, ticketData }) })
    return data
  }

  const batchDelete = async (ticketIds: Array<string>) => {
    const { data } = await useSamApi<Array<Ticket>>(`/tickets/batch-delete`, { method: "POST", body: JSON.stringify({ ticketIds }) })
    return data
  }

  const batchArticlesEdit = async (ticketIds: Array<string>, articlesToAdd?: Array<TicketArticle>) => {
    const { data } = await useSamApi<Array<Ticket>>(`/tickets/batch-articles-edit`, { method: "POST", body: JSON.stringify({ ticketIds, articlesToAdd }) })
    return data
  }

  const generatePdf = async (ticketId: string, options?: GenerateTicketPdfOptions) => {
    const searchParams = getUrlSearchParams(options)

    const { data: pdf } = await useSamApi<Blob>(`/tickets/${ticketId}/pdf?${searchParams}`, {
      headers: { "Accept": "*/*" },
      method: "POST",
    })

    return URL.createObjectURL(pdf)
  }

  return {
    tickets,
    listTickets,
    create,
    get,
    getById,
    getList,
    getListByAssetId,
    getAssetsLookup,
    getArticlesByPage,
    getListByArticleIds,
    getArticlesPdf,
    getListByPage,
    getListByPageWithActionCount,
    getInspectionAnalysis,
    getCountPerMonth,
    getCountPerStatus,
    update,

    addFile,
    deleteFile,
    getFiles,

    getTicketCount,

    batchHasLinkedData,
    batchEdit,
    batchCreate,
    batchDelete,
    batchArticlesEdit,

    generatePdf
  }
})
