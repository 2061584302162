import type { PaginateResult, QueryOptions } from "mongoose"
import type { Ref } from "vue"

export interface LookupItem {
  _id: string
  description: string
}

export function useCrudMethods<T extends { _id: string }>(endpoint: string, list?: Ref<Array<T>>) {
  async function createItem(item: Partial<T>): Promise<T> {
    const { data } = await useSamApi<T>(endpoint, { method: "POST", body: JSON.stringify(item) })
    list?.value.unshift(data)

    return data
  }

  async function updateItem(id: T["_id"], item: Partial<T>): Promise<T> {
    const { data } = await useSamApi<T>(`${endpoint}/${id}`, { method: "PUT", body: JSON.stringify(item) })

    if (list) {
      const existingItem = list.value.find((item) => item._id === id)
      if (existingItem) {
        list.value.splice(list.value.indexOf(existingItem), 1)
      }

      list.value.unshift(data)
    }

    return data
  }

  async function updateItemCustom(id: T["_id"], url: string, item: object): Promise<T> {
    const { data } = await useSamApi<T>(`${endpoint}/${url}`, { method: "PUT", body: JSON.stringify(item) })

    if (list) {
      const idx = list.value.findIndex((item) => item._id === id)
      if (idx > -1) {
        list.value.splice(idx, 1, data)
      } else {
        list.value.push(data)
      }
    }

    return data
  }

  async function readItems(queryParameters?: QueryOptions<T>): Promise<Array<T>> {
    try {
      const searchParams = new URLSearchParams()
      if (queryParameters) {
        Object.entries(queryParameters).forEach(([key, value]) => {
          if (value !== undefined && value !== null && value !== "") {
            searchParams.append(key, value)
          }
        })
      }

      const { data } = await useSamApi<PaginateResult<T>>(`${endpoint}${"?" + searchParams}`, { method: "GET" })

      if (data.totalDocs === data.docs.length) {
        if (list) { list.value = data.docs }
        return data.docs
      }

      if (list) {
        data.docs.forEach((item) => {
          const existingItem = list.value.find((i) => i._id === item._id)
          if (existingItem) {
            Object.assign(existingItem, item)
          } else {
            list.value.push(item)
          }
        })
      }

      return data.docs
    } catch (e: any) {
      console.error(e)
      throw new Error(e)
    }
  }

  async function readItemById(id: T["_id"]): Promise<T> {
    const { data } = await useSamApi<T>(`${endpoint}/${id}`)

    if (list) {
      const existingItem = list.value.find((item) => item._id === id)
      if (existingItem) {
        Object.assign(existingItem, data)
        return data
      }

      list.value.push(data)
    }

    return data
  }

  async function deleteItem(id: T["_id"]): Promise<T> {
    const { data } = await useSamApi<T>(`${endpoint}/${id}`, { method: "DELETE" })

    if (list) {
      const existingItem = list.value.find((item) => item._id === id)
      if (existingItem) {
        list.value.splice(list.value.indexOf(existingItem), 1)
      }
    }

    return data
  }

  /** @deprecated */
  async function lookupItems(): Promise<Array<LookupItem>> {
    const { data } = await useSamApi<{ data: Array<LookupItem> }>(`${endpoint}/lookup`)
    return data.data
  }

  return {
    createItem,
    readItems,
    readItemById,
    updateItem,
    updateItemCustom,
    deleteItem,
    lookupItems,
  }


}

export function useCrudMethodsAuth<T,U>(endpoint: string){
  async function postItem(item: Partial<T>): Promise<U> {
    const { data } = await useSamApi<U>(endpoint, { method: "POST", body: JSON.stringify(item) })

    return data
  }

  return {
    postItem
  }
}