import {
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
  DxDataGrid,
  DxExport,
  DxFilterRow,
  DxFormat,
  DxGroupPanel,
  DxGrouping,
  DxHeaderFilter,
  DxItem,
  DxMasterDetail,
  DxPager,
  DxPaging,
  DxScrolling,
  DxSearchPanel,
  DxSelection,
  DxSummary,
  DxToolbar,
  DxTotalItem,
  DxLookup,
  DxGroupItem,
} from "devextreme-vue/data-grid"

import config from "devextreme/core/config"

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp
    .component("DxColumn", DxColumn)
    .component("DxColumnChooser", DxColumnChooser)
    .component("DxColumnFixing", DxColumnFixing)
    .component("DxDataGrid", DxDataGrid)
    .component("DxExport", DxExport)
    .component("DxFilterRow", DxFilterRow)
    .component("DxFormat", DxFormat)
    .component("DxGroupPanel", DxGroupPanel)
    .component("DxGrouping", DxGrouping)
    .component("DxHeaderFilter", DxHeaderFilter)
    .component("DxItem", DxItem)
    .component("DxMasterDetail", DxMasterDetail)
    .component("DxPager", DxPager)
    .component("DxPaging", DxPaging)
    .component("DxScrolling", DxScrolling)
    .component("DxSearchPanel", DxSearchPanel)
    .component("DxSelection", DxSelection)
    .component("DxSummary", DxSummary)
    .component("DxToolbar", DxToolbar)
    .component("DxTotalItem", DxTotalItem)
    .component("DxGroupItem", DxGroupItem)
    .component("DxLookup", DxLookup)

  config({
    defaultCurrency: "EUR",
  })
})
