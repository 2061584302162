  export const modulesPerOrganization = ref<Record<string, Required<OrganizationSettings>["modules"]>>({})

export const useSettingsStore = defineStore('settings', () => {
  const authStore = useAuthStore()
  const { currentOrganization, isSuperAdmin } = storeToRefs(authStore)

  // Load organization modules if not already loaded
  if (currentOrganization.value && modulesPerOrganization.value[currentOrganization.value] === undefined) {
    const organizationStore = useOrganizationStore()

    organizationStore.getSettings(currentOrganization.value).then((settings) => {
      modulesPerOrganization.value[currentOrganization.value!] = settings.modules ?? {}
    })
  }

  const isInAdminPanel = computed(() => {
    return !currentOrganization.value && isSuperAdmin.value
  })

  const ssoModuleEnabled = computed(() => {
    if (isInAdminPanel.value) { return true }
    return !!currentOrganization.value && !!modulesPerOrganization.value[currentOrganization.value]?.sso
  })

  const ticketFinanceModuleEnabled = computed(() => {
    if (isInAdminPanel.value) { return true }
    return !!currentOrganization.value && !!modulesPerOrganization.value[currentOrganization.value]?.ticketFinance
  })

  const dataQualityModuleEnabled = computed(() => {
    if (isInAdminPanel.value) { return true }
    return !!currentOrganization.value && !!modulesPerOrganization.value[currentOrganization.value]?.dataQuality
  })

  const supplierModuleEnabled = computed(() => {
    if (isInAdminPanel.value) { return true }
    return !!currentOrganization.value && !!modulesPerOrganization.value[currentOrganization.value]?.supplier
  })

  const subSupplierModuleEnabled = computed(() => {
    if (isInAdminPanel.value) { return true }
    return !!currentOrganization.value && !!modulesPerOrganization.value[currentOrganization.value]?.subSupplier
  })

  return {
    ssoModuleEnabled,
    ticketFinanceModuleEnabled,
    dataQualityModuleEnabled,
    supplierModuleEnabled,
    subSupplierModuleEnabled,
  }
})