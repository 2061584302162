import { default as indexZVNUgh35d0Meta } from "/app/pages/admin/assets/asset-list/index.vue?macro=true";
import { default as _91id_93qfR4ZWHQwSMeta } from "/app/pages/admin/assets/component-types-[domain]/[id].vue?macro=true";
import { default as indexxbXXwuAVq2Meta } from "/app/pages/admin/assets/component-types-[domain]/index.vue?macro=true";
import { default as indexangGKSqwMjMeta } from "/app/pages/admin/assets/index.vue?macro=true";
import { default as _91id_93eQ95RmBYL8Meta } from "/app/pages/admin/assets/lookup-types-[domain]/[id].vue?macro=true";
import { default as indexCt1uMRFdqaMeta } from "/app/pages/admin/assets/lookup-types-[domain]/index.vue?macro=true";
import { default as _91id_93GmYo0HrwTOMeta } from "/app/pages/admin/assets/lookup-values/[id].vue?macro=true";
import { default as indexGANAUXXyMEMeta } from "/app/pages/admin/assets/lookup-values/index.vue?macro=true";
import { default as _91domain_93lZqzW7IQBDMeta } from "/app/pages/admin/assets/structure/[domain].vue?macro=true";
import { default as _91id_93ZohDh1NO2TMeta } from "/app/pages/admin/assets/templates-[domain]/[id].vue?macro=true";
import { default as indexYw4H7W0CJiMeta } from "/app/pages/admin/assets/templates-[domain]/index.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as indexLJTbDvvx1IMeta } from "/app/pages/admin/list-values-[domain]/index.vue?macro=true";
import { default as indexNpG51DTCuFMeta } from "/app/pages/admin/mutation-log/index.vue?macro=true";
import { default as _91organizationId_93H2vn0Kta0tMeta } from "/app/pages/admin/organizations/[organizationId].vue?macro=true";
import { default as index2ZHg7CJkN5Meta } from "/app/pages/admin/organizations/customer/[id]/index.vue?macro=true";
import { default as _91supplierId_93IFCkUb6A0xMeta } from "/app/pages/admin/organizations/customer/[id]/supplier/[supplierId].vue?macro=true";
import { default as indexPItleGRp5mMeta } from "/app/pages/admin/organizations/index.vue?macro=true";
import { default as _91id_93n2TqsEFDsWMeta } from "/app/pages/admin/organizations/label/[id].vue?macro=true";
import { default as _91id_93pN6DoMT9h5Meta } from "/app/pages/admin/organizations/supplier/[id].vue?macro=true";
import { default as _91id_93ZInGK2TYqxMeta } from "/app/pages/admin/release-notes/[id].vue?macro=true";
import { default as createNXc7fRgzQCMeta } from "/app/pages/admin/release-notes/create.vue?macro=true";
import { default as indexm5fL0jTDgtMeta } from "/app/pages/admin/release-notes/index.vue?macro=true";
import { default as indexqtX6hlPGkQMeta } from "/app/pages/admin/scripts/index.vue?macro=true";
import { default as indexihjjmckQP6Meta } from "/app/pages/admin/security-logs/index.vue?macro=true";
import { default as indexRtuELrsmeHMeta } from "/app/pages/admin/users/[id]/index.vue?macro=true";
import { default as _91org_93ezxERKDoA6Meta } from "/app/pages/admin/users/[id]/organization/[org].vue?macro=true";
import { default as indexYm9dzWmM2AMeta } from "/app/pages/admin/users/index.vue?macro=true";
import { default as admin0eiku7Jj1aMeta } from "/app/pages/admin.vue?macro=true";
import { default as data_45qualityzv5y3KytRpMeta } from "/app/pages/analytics/data-quality.vue?macro=true";
import { default as index3VG1nzc0GsMeta } from "/app/pages/analytics/index.vue?macro=true";
import { default as inspectionsUJ2bdGmgnRMeta } from "/app/pages/analytics/inspections.vue?macro=true";
import { default as riskkiJGZ4ZwUrMeta } from "/app/pages/analytics/risk.vue?macro=true";
import { default as analyticsawZ4iWXl3HMeta } from "/app/pages/analytics.vue?macro=true";
import { default as _91id_93dJSlMJUv8uMeta } from "/app/pages/assets/[id].vue?macro=true";
import { default as indexPAY8rTl3jRMeta } from "/app/pages/assets/index.vue?macro=true";
import { default as assetsl63h3HsNfZMeta } from "/app/pages/assets.vue?macro=true";
import { default as indexagNkUfW5A4Meta } from "/app/pages/componentgroups/index.vue?macro=true";
import { default as dashboardJu37u7k0toMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as _91externalKey_93M9d7XyBSonMeta } from "/app/pages/external/[externalKey].vue?macro=true";
import { default as _91id_93XNCSL52TtXMeta } from "/app/pages/finance/contracts/[id].vue?macro=true";
import { default as indexoTex98oXYqMeta } from "/app/pages/finance/contracts/index.vue?macro=true";
import { default as _91id_93JDFEfv0C6OMeta } from "/app/pages/finance/cost-specifications/[id].vue?macro=true";
import { default as index1HhfGgut9ZMeta } from "/app/pages/finance/cost-specifications/index.vue?macro=true";
import { default as indexjFREg1grRUMeta } from "/app/pages/finance/index.vue?macro=true";
import { default as myp3bu50drO3SMeta } from "/app/pages/finance/myp.vue?macro=true";
import { default as ticketsQGnHLj19jhMeta } from "/app/pages/finance/tickets.vue?macro=true";
import { default as financeMjIGPbjS9hMeta } from "/app/pages/finance.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as mutation_45logeKe62gBT0DMeta } from "/app/pages/mutation-log.vue?macro=true";
import { default as overviewsaWJnX4abV6Meta } from "/app/pages/overviews.vue?macro=true";
import { default as _91id_93dnWdlxkLHTMeta } from "/app/pages/settings/external-user/[id].vue?macro=true";
import { default as index11eXGkSJBcMeta } from "/app/pages/settings/index.vue?macro=true";
import { default as _91id_934EQhL3VrhwMeta } from "/app/pages/settings/key/[id].vue?macro=true";
import { default as _91id_936Mf89CvlVUMeta } from "/app/pages/settings/supplier/[id].vue?macro=true";
import { default as _91id_938jGjvQeOsWMeta } from "/app/pages/settings/user/[id].vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as _91id_93nMWtp1ei3xMeta } from "/app/pages/shared/assets/[id].vue?macro=true";
import { default as indexiPXLQkIdEUMeta } from "/app/pages/shared/assets/index.vue?macro=true";
import { default as indexQhC0kF6q9jMeta } from "/app/pages/shared/index.vue?macro=true";
import { default as _91id_93ittShquMWyMeta } from "/app/pages/shared/tickets/actions/[id].vue?macro=true";
import { default as index8oMHhIjPmRMeta } from "/app/pages/shared/tickets/index.vue?macro=true";
import { default as _91id_93Nt9mWaJpFxMeta } from "/app/pages/shared/tickets/inspections/[id].vue?macro=true";
import { default as _91id_93Mg7BWPkC5dMeta } from "/app/pages/shared/tickets/malfunctions/[id].vue?macro=true";
import { default as sharedgEEWV7IpFPMeta } from "/app/pages/shared.vue?macro=true";
import { default as indexrVEAE3qysDMeta } from "/app/pages/support/index.vue?macro=true";
import { default as _91issueKey_938A4OVpkPtCMeta } from "/app/pages/support/issues/[issueKey].vue?macro=true";
import { default as indexrJns74ez30Meta } from "/app/pages/support/issues/index.vue?macro=true";
import { default as release_45notesLD8YLR910vMeta } from "/app/pages/support/release-notes.vue?macro=true";
import { default as supportPKo8CaxFTuMeta } from "/app/pages/support.vue?macro=true";
import { default as _91id_93tTbcpTSSvBMeta } from "/app/pages/tickets/actions/[id].vue?macro=true";
import { default as indexSYGgMZWiZOMeta } from "/app/pages/tickets/index.vue?macro=true";
import { default as _91id_93TTABEXQ57NMeta } from "/app/pages/tickets/inspections/[id].vue?macro=true";
import { default as _91id_93kCPzCc33ihMeta } from "/app/pages/tickets/malfunctions/[id].vue?macro=true";
import { default as ticketsLMEjejk3YqMeta } from "/app/pages/tickets.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: admin0eiku7Jj1aMeta?.name,
    path: "/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-assets-asset-list",
    path: "assets/asset-list",
    meta: indexZVNUgh35d0Meta || {},
    component: () => import("/app/pages/admin/assets/asset-list/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-assets-component-types-domain-id",
    path: "assets/component-types-:domain()/:id()",
    meta: _91id_93qfR4ZWHQwSMeta || {},
    component: () => import("/app/pages/admin/assets/component-types-[domain]/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-assets-component-types-domain",
    path: "assets/component-types-:domain()",
    meta: indexxbXXwuAVq2Meta || {},
    component: () => import("/app/pages/admin/assets/component-types-[domain]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-assets",
    path: "assets",
    meta: indexangGKSqwMjMeta || {},
    component: () => import("/app/pages/admin/assets/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-assets-lookup-types-domain-id",
    path: "assets/lookup-types-:domain()/:id()",
    meta: _91id_93eQ95RmBYL8Meta || {},
    component: () => import("/app/pages/admin/assets/lookup-types-[domain]/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-assets-lookup-types-domain",
    path: "assets/lookup-types-:domain()",
    meta: indexCt1uMRFdqaMeta || {},
    component: () => import("/app/pages/admin/assets/lookup-types-[domain]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-assets-lookup-values-id",
    path: "assets/lookup-values/:id()",
    meta: _91id_93GmYo0HrwTOMeta || {},
    component: () => import("/app/pages/admin/assets/lookup-values/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-assets-lookup-values",
    path: "assets/lookup-values",
    meta: indexGANAUXXyMEMeta || {},
    component: () => import("/app/pages/admin/assets/lookup-values/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-assets-structure-domain",
    path: "assets/structure/:domain()",
    meta: _91domain_93lZqzW7IQBDMeta || {},
    component: () => import("/app/pages/admin/assets/structure/[domain].vue").then(m => m.default || m)
  },
  {
    name: "admin-assets-templates-domain-id",
    path: "assets/templates-:domain()/:id()",
    meta: _91id_93ZohDh1NO2TMeta || {},
    component: () => import("/app/pages/admin/assets/templates-[domain]/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-assets-templates-domain",
    path: "assets/templates-:domain()",
    meta: indexYw4H7W0CJiMeta || {},
    component: () => import("/app/pages/admin/assets/templates-[domain]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "",
    meta: indexi8nCATZISJMeta || {},
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-list-values-domain",
    path: "list-values-:domain()",
    component: () => import("/app/pages/admin/list-values-[domain]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-mutation-log",
    path: "mutation-log",
    meta: indexNpG51DTCuFMeta || {},
    component: () => import("/app/pages/admin/mutation-log/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations-organizationId",
    path: "organizations/:organizationId()",
    component: () => import("/app/pages/admin/organizations/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations-customer-id",
    path: "organizations/customer/:id()",
    meta: index2ZHg7CJkN5Meta || {},
    component: () => import("/app/pages/admin/organizations/customer/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations-customer-id-supplier-supplierId",
    path: "organizations/customer/:id()/supplier/:supplierId()",
    meta: _91supplierId_93IFCkUb6A0xMeta || {},
    component: () => import("/app/pages/admin/organizations/customer/[id]/supplier/[supplierId].vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations",
    path: "organizations",
    meta: indexPItleGRp5mMeta || {},
    component: () => import("/app/pages/admin/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations-label-id",
    path: "organizations/label/:id()",
    meta: _91id_93n2TqsEFDsWMeta || {},
    component: () => import("/app/pages/admin/organizations/label/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations-supplier-id",
    path: "organizations/supplier/:id()",
    meta: _91id_93pN6DoMT9h5Meta || {},
    component: () => import("/app/pages/admin/organizations/supplier/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-release-notes-id",
    path: "release-notes/:id()",
    meta: _91id_93ZInGK2TYqxMeta || {},
    component: () => import("/app/pages/admin/release-notes/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-release-notes-create",
    path: "release-notes/create",
    meta: createNXc7fRgzQCMeta || {},
    component: () => import("/app/pages/admin/release-notes/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-release-notes",
    path: "release-notes",
    meta: indexm5fL0jTDgtMeta || {},
    component: () => import("/app/pages/admin/release-notes/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-scripts",
    path: "scripts",
    meta: indexqtX6hlPGkQMeta || {},
    component: () => import("/app/pages/admin/scripts/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-security-logs",
    path: "security-logs",
    meta: indexihjjmckQP6Meta || {},
    component: () => import("/app/pages/admin/security-logs/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    meta: indexRtuELrsmeHMeta || {},
    component: () => import("/app/pages/admin/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id-organization-org",
    path: "users/:id()/organization/:org()",
    meta: _91org_93ezxERKDoA6Meta || {},
    component: () => import("/app/pages/admin/users/[id]/organization/[org].vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "users",
    meta: indexYm9dzWmM2AMeta || {},
    component: () => import("/app/pages/admin/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: analyticsawZ4iWXl3HMeta?.name,
    path: "/analytics",
    meta: analyticsawZ4iWXl3HMeta || {},
    component: () => import("/app/pages/analytics.vue").then(m => m.default || m),
    children: [
  {
    name: "analytics-data-quality",
    path: "data-quality",
    meta: data_45qualityzv5y3KytRpMeta || {},
    component: () => import("/app/pages/analytics/data-quality.vue").then(m => m.default || m)
  },
  {
    name: "analytics",
    path: "",
    meta: index3VG1nzc0GsMeta || {},
    component: () => import("/app/pages/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "analytics-inspections",
    path: "inspections",
    meta: inspectionsUJ2bdGmgnRMeta || {},
    component: () => import("/app/pages/analytics/inspections.vue").then(m => m.default || m)
  },
  {
    name: "analytics-risk",
    path: "risk",
    meta: riskkiJGZ4ZwUrMeta || {},
    component: () => import("/app/pages/analytics/risk.vue").then(m => m.default || m)
  }
]
  },
  {
    name: assetsl63h3HsNfZMeta?.name,
    path: "/assets",
    meta: assetsl63h3HsNfZMeta || {},
    component: () => import("/app/pages/assets.vue").then(m => m.default || m),
    children: [
  {
    name: "assets-id",
    path: ":id()",
    meta: _91id_93dJSlMJUv8uMeta || {},
    component: () => import("/app/pages/assets/[id].vue").then(m => m.default || m)
  },
  {
    name: "assets",
    path: "",
    meta: indexPAY8rTl3jRMeta || {},
    component: () => import("/app/pages/assets/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "componentgroups",
    path: "/componentgroups",
    meta: indexagNkUfW5A4Meta || {},
    component: () => import("/app/pages/componentgroups/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardJu37u7k0toMeta || {},
    component: () => import("/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "external-externalKey",
    path: "/external/:externalKey()",
    component: () => import("/app/pages/external/[externalKey].vue").then(m => m.default || m)
  },
  {
    name: financeMjIGPbjS9hMeta?.name,
    path: "/finance",
    meta: financeMjIGPbjS9hMeta || {},
    component: () => import("/app/pages/finance.vue").then(m => m.default || m),
    children: [
  {
    name: "finance-contracts-id",
    path: "contracts/:id()",
    meta: _91id_93XNCSL52TtXMeta || {},
    component: () => import("/app/pages/finance/contracts/[id].vue").then(m => m.default || m)
  },
  {
    name: "finance-contracts",
    path: "contracts",
    meta: indexoTex98oXYqMeta || {},
    component: () => import("/app/pages/finance/contracts/index.vue").then(m => m.default || m)
  },
  {
    name: "finance-cost-specifications-id",
    path: "cost-specifications/:id()",
    meta: _91id_93JDFEfv0C6OMeta || {},
    component: () => import("/app/pages/finance/cost-specifications/[id].vue").then(m => m.default || m)
  },
  {
    name: "finance-cost-specifications",
    path: "cost-specifications",
    meta: index1HhfGgut9ZMeta || {},
    component: () => import("/app/pages/finance/cost-specifications/index.vue").then(m => m.default || m)
  },
  {
    name: "finance",
    path: "",
    meta: indexjFREg1grRUMeta || {},
    component: () => import("/app/pages/finance/index.vue").then(m => m.default || m)
  },
  {
    name: "finance-myp",
    path: "myp",
    meta: myp3bu50drO3SMeta || {},
    component: () => import("/app/pages/finance/myp.vue").then(m => m.default || m)
  },
  {
    name: "finance-tickets",
    path: "tickets",
    meta: ticketsQGnHLj19jhMeta || {},
    component: () => import("/app/pages/finance/tickets.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mutation-log",
    path: "/mutation-log",
    meta: mutation_45logeKe62gBT0DMeta || {},
    component: () => import("/app/pages/mutation-log.vue").then(m => m.default || m)
  },
  {
    name: "overviews",
    path: "/overviews",
    meta: overviewsaWJnX4abV6Meta || {},
    component: () => import("/app/pages/overviews.vue").then(m => m.default || m)
  },
  {
    name: settingsLwEYOlkQxrMeta?.name,
    path: "/settings",
    meta: settingsLwEYOlkQxrMeta || {},
    component: () => import("/app/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-external-user-id",
    path: "external-user/:id()",
    meta: _91id_93dnWdlxkLHTMeta || {},
    component: () => import("/app/pages/settings/external-user/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    meta: index11eXGkSJBcMeta || {},
    component: () => import("/app/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-key-id",
    path: "key/:id()",
    meta: _91id_934EQhL3VrhwMeta || {},
    component: () => import("/app/pages/settings/key/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-supplier-id",
    path: "supplier/:id()",
    meta: _91id_936Mf89CvlVUMeta || {},
    component: () => import("/app/pages/settings/supplier/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-user-id",
    path: "user/:id()",
    meta: _91id_938jGjvQeOsWMeta || {},
    component: () => import("/app/pages/settings/user/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: sharedgEEWV7IpFPMeta?.name,
    path: "/shared",
    meta: sharedgEEWV7IpFPMeta || {},
    component: () => import("/app/pages/shared.vue").then(m => m.default || m),
    children: [
  {
    name: "shared-assets-id",
    path: "assets/:id()",
    meta: _91id_93nMWtp1ei3xMeta || {},
    component: () => import("/app/pages/shared/assets/[id].vue").then(m => m.default || m)
  },
  {
    name: "shared-assets",
    path: "assets",
    meta: indexiPXLQkIdEUMeta || {},
    component: () => import("/app/pages/shared/assets/index.vue").then(m => m.default || m)
  },
  {
    name: "shared",
    path: "",
    meta: indexQhC0kF6q9jMeta || {},
    component: () => import("/app/pages/shared/index.vue").then(m => m.default || m)
  },
  {
    name: "shared-tickets-actions-id",
    path: "tickets/actions/:id()",
    meta: _91id_93ittShquMWyMeta || {},
    component: () => import("/app/pages/shared/tickets/actions/[id].vue").then(m => m.default || m)
  },
  {
    name: "shared-tickets",
    path: "tickets",
    meta: index8oMHhIjPmRMeta || {},
    component: () => import("/app/pages/shared/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: "shared-tickets-inspections-id",
    path: "tickets/inspections/:id()",
    meta: _91id_93Nt9mWaJpFxMeta || {},
    component: () => import("/app/pages/shared/tickets/inspections/[id].vue").then(m => m.default || m)
  },
  {
    name: "shared-tickets-malfunctions-id",
    path: "tickets/malfunctions/:id()",
    meta: _91id_93Mg7BWPkC5dMeta || {},
    component: () => import("/app/pages/shared/tickets/malfunctions/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: supportPKo8CaxFTuMeta?.name,
    path: "/support",
    meta: supportPKo8CaxFTuMeta || {},
    component: () => import("/app/pages/support.vue").then(m => m.default || m),
    children: [
  {
    name: "support",
    path: "",
    meta: indexrVEAE3qysDMeta || {},
    component: () => import("/app/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "support-issues-issueKey",
    path: "issues/:issueKey()",
    meta: _91issueKey_938A4OVpkPtCMeta || {},
    component: () => import("/app/pages/support/issues/[issueKey].vue").then(m => m.default || m)
  },
  {
    name: "support-issues",
    path: "issues",
    meta: indexrJns74ez30Meta || {},
    component: () => import("/app/pages/support/issues/index.vue").then(m => m.default || m)
  },
  {
    name: "support-release-notes",
    path: "release-notes",
    meta: release_45notesLD8YLR910vMeta || {},
    component: () => import("/app/pages/support/release-notes.vue").then(m => m.default || m)
  }
]
  },
  {
    name: ticketsLMEjejk3YqMeta?.name,
    path: "/tickets",
    meta: ticketsLMEjejk3YqMeta || {},
    component: () => import("/app/pages/tickets.vue").then(m => m.default || m),
    children: [
  {
    name: "tickets-actions-id",
    path: "actions/:id()",
    meta: _91id_93tTbcpTSSvBMeta || {},
    component: () => import("/app/pages/tickets/actions/[id].vue").then(m => m.default || m)
  },
  {
    name: "tickets",
    path: "",
    meta: indexSYGgMZWiZOMeta || {},
    component: () => import("/app/pages/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: "tickets-inspections-id",
    path: "inspections/:id()",
    meta: _91id_93TTABEXQ57NMeta || {},
    component: () => import("/app/pages/tickets/inspections/[id].vue").then(m => m.default || m)
  },
  {
    name: "tickets-malfunctions-id",
    path: "malfunctions/:id()",
    meta: _91id_93kCPzCc33ihMeta || {},
    component: () => import("/app/pages/tickets/malfunctions/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/tickets/actions",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/tickets/inspections",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/tickets/malfunctions",
    component: component_45stubnO9MU04yTU
  }
]