import { jwtDecode } from "jwt-decode"
import type { Auth, User} from "firebase/auth"
import { confirmPasswordReset, sendPasswordResetEmail, signInWithEmailAndPassword, verifyPasswordResetCode, signInWithPopup, OAuthProvider  } from "firebase/auth"
import * as Sentry from "@sentry/vue"
import type { UserRecord } from "firebase-admin/auth"
import dayjs from "dayjs"
import type { DomainType, ScopeSelectionObject } from "./organization.store"

export enum ProviderId {
  // GOOGLE = "google.com";
  MICROSOFT = "microsoft.com"
}

export enum AuthScope {
  // asset_categories
  CAN_VIEW = "CAN_VIEW",
  OWNER = "OWNER",
  // organization
  ADMIN = "ADMIN",
  CAN_APPROVE_CONTRACTS = "CAN_APPROVE_CONTRACTS",
  CAN_BATCH_CREATE_ACTIONS = "CAN_BATCH_CREATE_ACTIONS",
  CAN_BATCH_CREATE_INSPECTIONS = "CAN_BATCH_CREATE_INSPECTIONS",
  CAN_BATCH_EDIT_ACTIONS = "CAN_BATCH_EDIT_ACTIONS",
  CAN_BATCH_EDIT_INSPECTIONS = "CAN_BATCH_EDIT_INSPECTIONS",
  CAN_BATCH_EDIT_MALFUNCTIONS = "CAN_BATCH_EDIT_MALFUNCTIONS",
  CAN_CREATE_ACTIONS = "CAN_CREATE_ACTIONS",
  CAN_CREATE_ASSET_RELATION_TYPE = "CAN_CREATE_ASSET_RELATION_TYPE",
  CAN_CREATE_ASSET_RELATIONS = "CAN_CREATE_ASSET_RELATIONS",
  CAN_CREATE_ASSETS = "CAN_CREATE_ASSETS",
  CAN_CREATE_CONTRACTS = "CAN_CREATE_CONTRACTS",
  CAN_CREATE_EXTERNAL_KEY_APPLICATION = "CAN_CREATE_EXTERNAL_KEY_APPLICATION",
  CAN_CREATE_FILES = "CAN_CREATE_FILES",
  CAN_CREATE_INSPECTION_TYPES = "CAN_CREATE_INSPECTION_TYPES",
  CAN_CREATE_INSPECTIONS = "CAN_CREATE_INSPECTIONS",
  CAN_CREATE_MALFUNCTIONS = "CAN_CREATE_MALFUNCTIONS",
  CAN_CREATE_MEASUREMENTS = "CAN_CREATE_MEASUREMENTS",
  CAN_CREATE_ORGANIZATIONS = "CAN_CREATE_ORGANIZATIONS",
  CAN_CREATE_RELEASE_NOTE = "CAN_CREATE_RELEASE_NOTE",
  CAN_CREATE_USERS = "CAN_CREATE_USERS",
  CAN_CHANGE_ACTION_STATUS_NEW = "CAN_CHANGE_ACTION_STATUS_NEW",
  CAN_CHANGE_ACTION_STATUS_QUOTATION = "CAN_CHANGE_ACTION_STATUS_QUOTATION",
  CAN_CHANGE_ACTION_STATUS_QUOTE_AWAIT_APPROVAL = "CAN_CHANGE_ACTION_STATUS_QUOTE_AWAIT_APPROVAL",
  CAN_CHANGE_ACTION_STATUS_QUOTE_ACCEPTED = "CAN_CHANGE_ACTION_STATUS_QUOTE_ACCEPTED",
  CAN_CHANGE_ACTION_STATUS_QUOTE_REJECTED = "CAN_CHANGE_ACTION_STATUS_QUOTE_REJECTED",
  CAN_CHANGE_ACTION_STATUS_IN_ORDER = "CAN_CHANGE_ACTION_STATUS_IN_ORDER",
  CAN_CHANGE_ACTION_STATUS_PROCESSING = "CAN_CHANGE_ACTION_STATUS_PROCESSING",
  CAN_CHANGE_ACTION_STATUS_DID_NOT_PROCESS = "CAN_CHANGE_ACTION_STATUS_DID_NOT_PROCESS",
  CAN_CHANGE_ACTION_STATUS_PROCESSED = "CAN_CHANGE_ACTION_STATUS_PROCESSED",
  CAN_CHANGE_ACTION_STATUS_AWAIT_ACCEPTANCE = "CAN_CHANGE_ACTION_STATUS_AWAIT_ACCEPTANCE",
  CAN_CHANGE_ACTION_STATUS_ACCEPTED = "CAN_CHANGE_ACTION_STATUS_ACCEPTED",
  CAN_CHANGE_ACTION_STATUS_REJECTED = "CAN_CHANGE_ACTION_STATUS_REJECTED",
  CAN_CHANGE_ACTION_STATUS_INVOICED = "CAN_CHANGE_ACTION_STATUS_INVOICED",
  CAN_CHANGE_ACTION_STATUS_DONE = "CAN_CHANGE_ACTION_STATUS_DONE",
  CAN_CHANGE_INSPECTION_STATUS_NEW = "CAN_CHANGE_INSPECTION_STATUS_NEW",
  CAN_CHANGE_INSPECTION_STATUS_QUOTATION = "CAN_CHANGE_INSPECTION_STATUS_QUOTATION",
  CAN_CHANGE_INSPECTION_STATUS_QUOTE_AWAIT_APPROVAL = "CAN_CHANGE_INSPECTION_STATUS_QUOTE_AWAIT_APPROVAL",
  CAN_CHANGE_INSPECTION_STATUS_QUOTE_ACCEPTED = "CAN_CHANGE_INSPECTION_STATUS_QUOTE_ACCEPTED",
  CAN_CHANGE_INSPECTION_STATUS_QUOTE_REJECTED = "CAN_CHANGE_INSPECTION_STATUS_QUOTE_REJECTED",
  CAN_CHANGE_INSPECTION_STATUS_IN_ORDER = "CAN_CHANGE_INSPECTION_STATUS_IN_ORDER",
  CAN_CHANGE_INSPECTION_STATUS_PROCESSING = "CAN_CHANGE_INSPECTION_STATUS_PROCESSING",
  CAN_CHANGE_INSPECTION_STATUS_DID_NOT_PROCESS = "CAN_CHANGE_INSPECTION_STATUS_DID_NOT_PROCESS",
  CAN_CHANGE_INSPECTION_STATUS_PROCESSED = "CAN_CHANGE_INSPECTION_STATUS_PROCESSED",
  CAN_CHANGE_INSPECTION_STATUS_AWAIT_ACCEPTANCE = "CAN_CHANGE_INSPECTION_STATUS_AWAIT_ACCEPTANCE",
  CAN_CHANGE_INSPECTION_STATUS_ACCEPTED = "CAN_CHANGE_INSPECTION_STATUS_ACCEPTED",
  CAN_CHANGE_INSPECTION_STATUS_REJECTED = "CAN_CHANGE_INSPECTION_STATUS_REJECTED",
  CAN_CHANGE_INSPECTION_STATUS_INVOICED = "CAN_CHANGE_INSPECTION_STATUS_INVOICED",
  CAN_CHANGE_INSPECTION_STATUS_DONE = "CAN_CHANGE_INSPECTION_STATUS_DONE",
  CAN_CHANGE_MALFUNCTION_STATUS_NEW = "CAN_CHANGE_MALFUNCTION_STATUS_NEW",
  CAN_CHANGE_MALFUNCTION_STATUS_QUOTATION = "CAN_CHANGE_MALFUNCTION_STATUS_QUOTATION",
  CAN_CHANGE_MALFUNCTION_STATUS_QUOTE_AWAIT_APPROVAL = "CAN_CHANGE_MALFUNCTION_STATUS_QUOTE_AWAIT_APPROVAL",
  CAN_CHANGE_MALFUNCTION_STATUS_QUOTE_ACCEPTED = "CAN_CHANGE_MALFUNCTION_STATUS_QUOTE_ACCEPTED",
  CAN_CHANGE_MALFUNCTION_STATUS_QUOTE_REJECTED = "CAN_CHANGE_MALFUNCTION_STATUS_QUOTE_REJECTED",
  CAN_CHANGE_MALFUNCTION_STATUS_IN_ORDER = "CAN_CHANGE_MALFUNCTION_STATUS_IN_ORDER",
  CAN_CHANGE_MALFUNCTION_STATUS_PROCESSING = "CAN_CHANGE_MALFUNCTION_STATUS_PROCESSING",
  CAN_CHANGE_MALFUNCTION_STATUS_DID_NOT_PROCESS = "CAN_CHANGE_MALFUNCTION_STATUS_DID_NOT_PROCESS",
  CAN_CHANGE_MALFUNCTION_STATUS_PROCESSED = "CAN_CHANGE_MALFUNCTION_STATUS_PROCESSED",
  CAN_CHANGE_MALFUNCTION_STATUS_AWAIT_ACCEPTANCE = "CAN_CHANGE_MALFUNCTION_STATUS_AWAIT_ACCEPTANCE",
  CAN_CHANGE_MALFUNCTION_STATUS_ACCEPTED = "CAN_CHANGE_MALFUNCTION_STATUS_ACCEPTED",
  CAN_CHANGE_MALFUNCTION_STATUS_REJECTED = "CAN_CHANGE_MALFUNCTION_STATUS_REJECTED",
  CAN_CHANGE_MALFUNCTION_STATUS_INVOICED = "CAN_CHANGE_MALFUNCTION_STATUS_INVOICED",
  CAN_CHANGE_MALFUNCTION_STATUS_DONE = "CAN_CHANGE_MALFUNCTION_STATUS_DONE",
  CAN_DELETE_ACTIONS = "CAN_DELETE_ACTIONS",
  CAN_DELETE_ASSET_RELATION_TYPE = "CAN_DELETE_ASSET_RELATION_TYPE",
  CAN_DELETE_ASSET_RELATIONS = "CAN_DELETE_ASSET_RELATIONS",
  CAN_DELETE_ASSETS = "CAN_DELETE_ASSETS",
  CAN_DELETE_CONTRACTS = "CAN_DELETE_CONTRACTS",
  CAN_DELETE_EXTERNAL_KEY_APPLICATION = "CAN_DELETE_EXTERNAL_KEY_APPLICATION",
  CAN_DELETE_FILES = "CAN_DELETE_FILES",
  CAN_DELETE_INSPECTION_TYPES = "CAN_DELETE_INSPECTION_TYPES",
  CAN_DELETE_INSPECTIONS = "CAN_DELETE_INSPECTIONS",
  CAN_DELETE_MALFUNCTIONS = "CAN_DELETE_MALFUNCTIONS",
  CAN_DELETE_MEASUREMENTS = "CAN_DELETE_MEASUREMENTS",
  CAN_DELETE_ORGANIZATIONS = "CAN_DELETE_ORGANIZATIONS",
  CAN_DELETE_RELEASE_NOTE = "CAN_DELETE_RELEASE_NOTE",
  CAN_DELETE_USERS = "CAN_DELETE_USERS",
  CAN_HANDLE_CLASSIFIED_FILES = "CAN_HANDLE_CLASSIFIED_FILES",
  CAN_SEE_CONTRACT_COSTS_OVERVIEW = "CAN_SEE_CONTRACT_COSTS_OVERVIEW",
  CAN_SEE_CONTRACT_PRICES = "CAN_SEE_CONTRACT_PRICES",
  CAN_SELECT_ALL_USERS = "CAN_SELECT_ALL_USERS",
  CAN_TERMINATE_CONTRACTS = "CAN_TERMINATE_CONTRACTS",
  CAN_UPDATE_ACTIONS = "CAN_UPDATE_ACTIONS",
  CAN_UPDATE_ASSET_RELATION_TYPE = "CAN_UPDATE_ASSET_RELATION_TYPE",
  CAN_UPDATE_ASSET_RELATIONS = "CAN_UPDATE_ASSET_RELATIONS",
  CAN_UPDATE_ASSETS_COMPONENT_INFO = "CAN_UPDATE_ASSETS_COMPONENT_INFO",
  CAN_UPDATE_ASSETS_CORE_INFO = "CAN_UPDATE_ASSETS_CORE_INFO",
  CAN_UPDATE_ASSETS_EXTERNAL_KEYS = "CAN_UPDATE_ASSETS_EXTERNAL_KEYS",
  CAN_UPDATE_ASSETS_FINANCES = "CAN_UPDATE_ASSETS_FINANCES",
  CAN_UPDATE_ASSETS_LOCATION_INFO = "CAN_UPDATE_ASSETS_LOCATION_INFO",
  CAN_UPDATE_ASSETS_PASSPORT_CONDITIONS = "CAN_UPDATE_ASSETS_PASSPORT_CONDITIONS",
  CAN_UPDATE_CONTRACTS = "CAN_UPDATE_CONTRACTS",
  CAN_UPDATE_EXTERNAL_KEY_APPLICATION = "CAN_UPDATE_EXTERNAL_KEY_APPLICATION",
  CAN_UPDATE_FILES = "CAN_UPDATE_FILES",
  CAN_UPDATE_INSPECTION_TYPES = "CAN_UPDATE_INSPECTION_TYPES",
  CAN_UPDATE_INSPECTIONS = "CAN_UPDATE_INSPECTIONS",
  CAN_UPDATE_MALFUNCTIONS = "CAN_UPDATE_MALFUNCTIONS",
  CAN_UPDATE_MEASUREMENTS = "CAN_UPDATE_MEASUREMENTS",
  CAN_UPDATE_ORGANIZATIONS = "CAN_UPDATE_ORGANIZATIONS",
  CAN_UPDATE_RELEASE_NOTE = "CAN_UPDATE_RELEASE_NOTE",
  CAN_UPDATE_USERS = "CAN_UPDATE_USERS",
  CAN_VIEW_ACTIONS_OVERVIEW = "CAN_VIEW_ACTIONS_OVERVIEW",
  CAN_VIEW_ANALYSIS_INSPECTION_OVERVIEW = "CAN_VIEW_ANALYSIS_INSPECTION_OVERVIEW",
  CAN_VIEW_ANALYSIS_RISK_OVERVIEW = "CAN_VIEW_ANALYSIS_RISK_OVERVIEW",
  CAN_VIEW_ASSET_RELATIONS = "CAN_VIEW_ASSET_RELATIONS",
  CAN_VIEW_ASSET_RELATIONS_TYPE = "CAN_VIEW_ASSET_RELATIONS_TYPE",
  CAN_VIEW_ASSETS = "CAN_VIEW_ASSETS",
  CAN_VIEW_ASSETS_FINANCES = "CAN_VIEW_ASSETS_FINANCES",
  CAN_VIEW_ACTIONS = "CAN_VIEW_ACTIONS",
  CAN_VIEW_MALFUNCTIONS = "CAN_VIEW_MALFUNCTIONS",
  CAN_VIEW_CHANGELOGS_OVERVIEW = "CAN_VIEW_CHANGELOGS_OVERVIEW",
  CAN_VIEW_FILES = "CAN_VIEW_FILES",
  CAN_VIEW_FINANCES_BUDGET_OVERVIEW = "CAN_VIEW_FINANCES_BUDGET_OVERVIEW",
  CAN_VIEW_FINANCES_COSTS_AND_LABOUR_OVERVIEW = "CAN_VIEW_FINANCES_COSTS_AND_LABOUR_OVERVIEW",
  CAN_VIEW_INSPECTIONS_OVERVIEW = "CAN_VIEW_INSPECTIONS_OVERVIEW",
  CAN_VIEW_MALFUNCTIONS_OVERVIEW = "CAN_VIEW_MALFUNCTIONS_OVERVIEW",
  CAN_VIEW_MEASUREMENTS = "CAN_VIEW_MEASUREMENTS",
  CAN_VIEW_OBJECTS_ELECTRICS_OVERVIEW = "CAN_VIEW_OBJECTS_ELECTRICS_OVERVIEW",
  CAN_VIEW_OBJECTS_OVERVIEW = "CAN_VIEW_OBJECTS_OVERVIEW",
  CAN_VIEW_OBJECTS_PAIRING_CODES_OVERVIEW = "CAN_VIEW_OBJECTS_PAIRING_CODES_OVERVIEW",
  CAN_VIEW_OBJECTS_PUMPS_OVERVIEW = "CAN_VIEW_OBJECTS_PUMPS_OVERVIEW",
  CAN_VIEW_OBJECTS_READINGS_OVERVIEW = "CAN_VIEW_OBJECTS_READINGS_OVERVIEW",
  CAN_VIEW_OBJECTS_RELATIONS_OVERVIEW = "CAN_VIEW_OBJECTS_RELATIONS_OVERVIEW",
  CAN_VIEW_OBJECTS_CONSTRUCTION_OVERVIEW = "CAN_VIEW_OBJECTS_CONSTRUCTION_OVERVIEW",
  CAN_VIEW_ORGANIZATIONS = "CAN_VIEW_ORGANIZATIONS",
  CAN_VIEW_INSPECTIONS = "CAN_VIEW_INSPECTIONS",
  CAN_VIEW_USERS = "CAN_VIEW_USERS",
  MAINTAINER = "MAINTAINER",
  MECHANIC = "MECHANIC",
  PLANNER = "PLANNER",
  CAN_LOGIN = "CAN_LOGIN",
  CAN_BE_ASSIGNED = "CAN_BE_ASSIGNED",
  CAN_ASSIGN_USERS = "CAN_ASSIGN_USERS",
  CAN_BATCH_EDIT_ASSETS = "CAN_BATCH_EDIT_ASSETS",
  /** Can see inspections from all organizations - not only his own (only for suppliers) */
  CAN_VIEW_INSPECTIONS_ALL = "CAN_VIEW_INSPECTIONS_ALL",
  /** Can see malfunctions from all organizations - not only his own (only for suppliers) */
  CAN_VIEW_MALFUNCTIONS_ALL = "CAN_VIEW_MALFUNCTIONS_ALL",
  /** Can see actions from all organizations - not only his own (only for suppliers) */
  CAN_VIEW_ACTIONS_ALL = "CAN_VIEW_ACTIONS_ALL",
}

export const generalUserScopes = [
  AuthScope.CAN_LOGIN,
  AuthScope.ADMIN,
  AuthScope.CAN_ASSIGN_USERS,
  AuthScope.CAN_BE_ASSIGNED
]

export const assetScopes = [
  AuthScope.CAN_VIEW_ASSETS,
  AuthScope.CAN_UPDATE_ASSETS_COMPONENT_INFO,
  AuthScope.CAN_UPDATE_ASSETS_CORE_INFO,
  AuthScope.CAN_UPDATE_ASSETS_EXTERNAL_KEYS,
  AuthScope.CAN_UPDATE_ASSETS_LOCATION_INFO,
  AuthScope.CAN_UPDATE_ASSETS_PASSPORT_CONDITIONS,
  AuthScope.CAN_CREATE_ASSETS,
  AuthScope.CAN_DELETE_ASSETS,
  AuthScope.CAN_VIEW_ASSET_RELATIONS,
  AuthScope.CAN_CREATE_ASSET_RELATIONS,
  AuthScope.CAN_UPDATE_ASSET_RELATIONS,
  AuthScope.CAN_DELETE_ASSET_RELATIONS,
  AuthScope.CAN_VIEW_ASSET_RELATIONS_TYPE,
  AuthScope.CAN_BATCH_EDIT_ASSETS,
  AuthScope.CAN_VIEW_ASSETS_FINANCES,
  AuthScope.CAN_UPDATE_ASSETS_FINANCES
]

export const supplierAssetScopes = [
    AuthScope.CAN_VIEW_ASSETS,
    AuthScope.CAN_UPDATE_ASSETS_COMPONENT_INFO,
    AuthScope.CAN_UPDATE_ASSETS_CORE_INFO,
    AuthScope.CAN_UPDATE_ASSETS_EXTERNAL_KEYS,
    AuthScope.CAN_UPDATE_ASSETS_LOCATION_INFO,
    AuthScope.CAN_UPDATE_ASSETS_PASSPORT_CONDITIONS,
    AuthScope.CAN_CREATE_ASSETS,
    AuthScope.CAN_DELETE_ASSETS,
    AuthScope.CAN_VIEW_ASSET_RELATIONS,
    AuthScope.CAN_VIEW_ASSET_RELATIONS_TYPE
]

export const overviewScopes = [
  AuthScope.CAN_VIEW_FINANCES_BUDGET_OVERVIEW,
  AuthScope.CAN_SEE_CONTRACT_COSTS_OVERVIEW,
  AuthScope.CAN_VIEW_FINANCES_COSTS_AND_LABOUR_OVERVIEW,
  AuthScope.CAN_VIEW_OBJECTS_OVERVIEW,
  AuthScope.CAN_VIEW_OBJECTS_ELECTRICS_OVERVIEW,
  AuthScope.CAN_VIEW_OBJECTS_PAIRING_CODES_OVERVIEW,
  AuthScope.CAN_VIEW_OBJECTS_PUMPS_OVERVIEW,
  AuthScope.CAN_VIEW_OBJECTS_READINGS_OVERVIEW,
  AuthScope.CAN_VIEW_OBJECTS_RELATIONS_OVERVIEW,
  AuthScope.CAN_VIEW_OBJECTS_CONSTRUCTION_OVERVIEW,
  AuthScope.CAN_VIEW_INSPECTIONS_OVERVIEW,
  AuthScope.CAN_VIEW_MALFUNCTIONS_OVERVIEW,
  AuthScope.CAN_VIEW_CHANGELOGS_OVERVIEW,
  AuthScope.CAN_VIEW_ACTIONS_OVERVIEW,
  AuthScope.CAN_VIEW_ANALYSIS_INSPECTION_OVERVIEW,
  AuthScope.CAN_VIEW_ANALYSIS_RISK_OVERVIEW
]

export const assetRelationScopes = [
  AuthScope.CAN_VIEW_ASSET_RELATIONS,
  AuthScope.CAN_CREATE_ASSET_RELATIONS,
  AuthScope.CAN_UPDATE_ASSET_RELATIONS,
  AuthScope.CAN_DELETE_ASSET_RELATIONS
]

export const inspectionScopes = [
  AuthScope.CAN_VIEW_INSPECTIONS,
  AuthScope.CAN_UPDATE_INSPECTIONS,
  AuthScope.CAN_BATCH_CREATE_INSPECTIONS,
  AuthScope.CAN_CREATE_INSPECTIONS,
  AuthScope.CAN_BATCH_EDIT_INSPECTIONS,
  AuthScope.CAN_DELETE_INSPECTIONS
]

export const malfunctionScopes = [
  AuthScope.CAN_VIEW_MALFUNCTIONS,
  AuthScope.CAN_UPDATE_MALFUNCTIONS,
  AuthScope.CAN_BATCH_EDIT_MALFUNCTIONS,
  AuthScope.CAN_CREATE_MALFUNCTIONS,
  AuthScope.CAN_DELETE_MALFUNCTIONS
]

export const actionScopes = [
  AuthScope.CAN_VIEW_ACTIONS,
  AuthScope.CAN_CREATE_ACTIONS,
  AuthScope.CAN_BATCH_CREATE_ACTIONS,
  AuthScope.CAN_UPDATE_ACTIONS,
  AuthScope.CAN_BATCH_EDIT_ACTIONS,
  AuthScope.CAN_DELETE_ACTIONS
]

export const fileScopes = [
  AuthScope.CAN_CREATE_FILES,
  AuthScope.CAN_DELETE_FILES,
  AuthScope.CAN_UPDATE_FILES,
  AuthScope.CAN_VIEW_FILES,
  AuthScope.CAN_HANDLE_CLASSIFIED_FILES
]

export const ticketVisibilityScopes = [
  AuthScope.CAN_VIEW_ACTIONS_ALL,
  AuthScope.CAN_VIEW_INSPECTIONS_ALL,
  AuthScope.CAN_VIEW_MALFUNCTIONS_ALL
]

export const contractScopes = [
  AuthScope.CAN_UPDATE_CONTRACTS,
  AuthScope.CAN_CREATE_CONTRACTS,
  AuthScope.CAN_APPROVE_CONTRACTS,
  AuthScope.CAN_SEE_CONTRACT_PRICES,
  AuthScope.CAN_TERMINATE_CONTRACTS,
  AuthScope.CAN_DELETE_CONTRACTS,
]

export const supplierContractScopes = [
  AuthScope.CAN_UPDATE_CONTRACTS,
  AuthScope.CAN_CREATE_CONTRACTS,
  AuthScope.CAN_SEE_CONTRACT_PRICES,
]

export const actionStatusScopes = [
  AuthScope.CAN_CHANGE_ACTION_STATUS_NEW,
  AuthScope.CAN_CHANGE_ACTION_STATUS_QUOTATION,
  AuthScope.CAN_CHANGE_ACTION_STATUS_QUOTE_AWAIT_APPROVAL,
  AuthScope.CAN_CHANGE_ACTION_STATUS_QUOTE_ACCEPTED,
  AuthScope.CAN_CHANGE_ACTION_STATUS_QUOTE_REJECTED,
  AuthScope.CAN_CHANGE_ACTION_STATUS_IN_ORDER,
  AuthScope.CAN_CHANGE_ACTION_STATUS_PROCESSING,
  AuthScope.CAN_CHANGE_ACTION_STATUS_DID_NOT_PROCESS,
  AuthScope.CAN_CHANGE_ACTION_STATUS_PROCESSED,
  AuthScope.CAN_CHANGE_ACTION_STATUS_AWAIT_ACCEPTANCE,
  AuthScope.CAN_CHANGE_ACTION_STATUS_ACCEPTED,
  AuthScope.CAN_CHANGE_ACTION_STATUS_REJECTED,
  AuthScope.CAN_CHANGE_ACTION_STATUS_INVOICED,
  AuthScope.CAN_CHANGE_ACTION_STATUS_DONE
]

export const inspectionStatusScopes = [
  AuthScope.CAN_CHANGE_INSPECTION_STATUS_NEW,
  AuthScope.CAN_CHANGE_INSPECTION_STATUS_QUOTATION,
  AuthScope.CAN_CHANGE_INSPECTION_STATUS_QUOTE_AWAIT_APPROVAL,
  AuthScope.CAN_CHANGE_INSPECTION_STATUS_QUOTE_ACCEPTED,
  AuthScope.CAN_CHANGE_INSPECTION_STATUS_QUOTE_REJECTED,
  AuthScope.CAN_CHANGE_INSPECTION_STATUS_IN_ORDER,
  AuthScope.CAN_CHANGE_INSPECTION_STATUS_PROCESSING,
  AuthScope.CAN_CHANGE_INSPECTION_STATUS_DID_NOT_PROCESS,
  AuthScope.CAN_CHANGE_INSPECTION_STATUS_PROCESSED,
  AuthScope.CAN_CHANGE_INSPECTION_STATUS_AWAIT_ACCEPTANCE,
  AuthScope.CAN_CHANGE_INSPECTION_STATUS_ACCEPTED,
  AuthScope.CAN_CHANGE_INSPECTION_STATUS_REJECTED,
  AuthScope.CAN_CHANGE_INSPECTION_STATUS_INVOICED,
  AuthScope.CAN_CHANGE_INSPECTION_STATUS_DONE
]

export const malfunctionStatusScopes = [
  AuthScope.CAN_CHANGE_MALFUNCTION_STATUS_NEW,
  AuthScope.CAN_CHANGE_MALFUNCTION_STATUS_QUOTATION,
  AuthScope.CAN_CHANGE_MALFUNCTION_STATUS_QUOTE_AWAIT_APPROVAL,
  AuthScope.CAN_CHANGE_MALFUNCTION_STATUS_QUOTE_ACCEPTED,
  AuthScope.CAN_CHANGE_MALFUNCTION_STATUS_QUOTE_REJECTED,
  AuthScope.CAN_CHANGE_MALFUNCTION_STATUS_IN_ORDER,
  AuthScope.CAN_CHANGE_MALFUNCTION_STATUS_PROCESSING,
  AuthScope.CAN_CHANGE_MALFUNCTION_STATUS_DID_NOT_PROCESS,
  AuthScope.CAN_CHANGE_MALFUNCTION_STATUS_PROCESSED,
  AuthScope.CAN_CHANGE_MALFUNCTION_STATUS_AWAIT_ACCEPTANCE,
  AuthScope.CAN_CHANGE_MALFUNCTION_STATUS_ACCEPTED,
  AuthScope.CAN_CHANGE_MALFUNCTION_STATUS_REJECTED,
  AuthScope.CAN_CHANGE_MALFUNCTION_STATUS_INVOICED,
  AuthScope.CAN_CHANGE_MALFUNCTION_STATUS_DONE
]

export const statusScopes = actionStatusScopes.concat(malfunctionStatusScopes).concat(inspectionStatusScopes)

export const basicViewScopes = [
  AuthScope.CAN_VIEW_ASSETS,
  AuthScope.CAN_VIEW_ASSET_RELATIONS,
  AuthScope.CAN_VIEW_ASSET_RELATIONS_TYPE,
  AuthScope.CAN_VIEW_ACTIONS,
  AuthScope.CAN_VIEW_INSPECTIONS,
  AuthScope.CAN_VIEW_MALFUNCTIONS,
  AuthScope.CAN_VIEW_ORGANIZATIONS,
  AuthScope.CAN_VIEW_USERS,
  AuthScope.CAN_VIEW_FILES,
  AuthScope.CAN_VIEW_MEASUREMENTS,
]

export const basicEditScopes = [
  AuthScope.CAN_CREATE_FILES,
  AuthScope.CAN_CREATE_MEASUREMENTS,
  AuthScope.CAN_CREATE_ACTIONS,
  AuthScope.CAN_CREATE_INSPECTIONS,
  AuthScope.CAN_CREATE_MALFUNCTIONS,
  AuthScope.CAN_UPDATE_ASSETS_COMPONENT_INFO,
  AuthScope.CAN_UPDATE_ASSETS_CORE_INFO,
  AuthScope.CAN_UPDATE_ASSETS_LOCATION_INFO,
  AuthScope.CAN_UPDATE_ASSET_RELATIONS,
  AuthScope.CAN_UPDATE_ACTIONS,
  AuthScope.CAN_UPDATE_INSPECTIONS,
  AuthScope.CAN_UPDATE_MALFUNCTIONS,
  AuthScope.CAN_UPDATE_FILES,
  AuthScope.CAN_UPDATE_MEASUREMENTS,
  AuthScope.CAN_DELETE_ASSET_RELATIONS,
  AuthScope.CAN_DELETE_FILES,
  AuthScope.CAN_DELETE_MEASUREMENTS,
]

export const advancedViewScopes = [
  AuthScope.CAN_VIEW_ASSETS_FINANCES,
  AuthScope.CAN_VIEW_ACTIONS_ALL,
  AuthScope.CAN_VIEW_INSPECTIONS_ALL,
  AuthScope.CAN_VIEW_MALFUNCTIONS_ALL,
  AuthScope.CAN_VIEW_ACTIONS_OVERVIEW,
  AuthScope.CAN_VIEW_INSPECTIONS_OVERVIEW,
  AuthScope.CAN_VIEW_MALFUNCTIONS_OVERVIEW,
  AuthScope.CAN_VIEW_ANALYSIS_INSPECTION_OVERVIEW,
  AuthScope.CAN_VIEW_ANALYSIS_RISK_OVERVIEW,
  AuthScope.CAN_VIEW_CHANGELOGS_OVERVIEW,
  AuthScope.CAN_VIEW_FINANCES_BUDGET_OVERVIEW,
  AuthScope.CAN_VIEW_FINANCES_COSTS_AND_LABOUR_OVERVIEW,
  AuthScope.CAN_VIEW_OBJECTS_ELECTRICS_OVERVIEW,
  AuthScope.CAN_VIEW_OBJECTS_OVERVIEW,
  AuthScope.CAN_VIEW_OBJECTS_PAIRING_CODES_OVERVIEW,
  AuthScope.CAN_VIEW_OBJECTS_PUMPS_OVERVIEW,
  AuthScope.CAN_VIEW_OBJECTS_READINGS_OVERVIEW,
  AuthScope.CAN_VIEW_OBJECTS_RELATIONS_OVERVIEW,
  AuthScope.CAN_SEE_CONTRACT_COSTS_OVERVIEW,
  AuthScope.CAN_VIEW_OBJECTS_CONSTRUCTION_OVERVIEW
]

export const advancedEditScopes = [
  AuthScope.CAN_CREATE_ASSETS,
  AuthScope.CAN_CREATE_ASSET_RELATIONS,
  AuthScope.CAN_CREATE_ASSET_RELATION_TYPE,
  AuthScope.CAN_CREATE_CONTRACTS,
  AuthScope.CAN_UPDATE_ASSETS_EXTERNAL_KEYS,
  AuthScope.CAN_UPDATE_ASSETS_FINANCES,
  AuthScope.CAN_UPDATE_ASSETS_PASSPORT_CONDITIONS,
  AuthScope.CAN_UPDATE_ASSET_RELATION_TYPE,
  AuthScope.CAN_UPDATE_CONTRACTS,
  AuthScope.CAN_UPDATE_ORGANIZATIONS,
  AuthScope.CAN_DELETE_ASSETS,
  AuthScope.CAN_DELETE_ASSET_RELATION_TYPE,
  AuthScope.CAN_DELETE_CONTRACTS,
  AuthScope.CAN_DELETE_ACTIONS,
  AuthScope.CAN_DELETE_INSPECTIONS,
  AuthScope.CAN_DELETE_MALFUNCTIONS,
  AuthScope.CAN_DELETE_EXTERNAL_KEY_APPLICATION,
]

export const batchScopes = [
  AuthScope.CAN_BATCH_CREATE_ACTIONS,
  AuthScope.CAN_BATCH_CREATE_INSPECTIONS,
  AuthScope.CAN_BATCH_EDIT_ACTIONS,
  AuthScope.CAN_BATCH_EDIT_INSPECTIONS,
  AuthScope.CAN_BATCH_EDIT_MALFUNCTIONS,
  AuthScope.CAN_BATCH_EDIT_ASSETS,
]

export const measurementScopes = [
  AuthScope.CAN_VIEW_MEASUREMENTS,
  AuthScope.CAN_CREATE_MEASUREMENTS,
  AuthScope.CAN_UPDATE_MEASUREMENTS,
  AuthScope.CAN_DELETE_MEASUREMENTS,
]

export interface FirebaseTokenInfo {
  identities: Array<{provider:string, values: Array<string>}>
}

export interface UserScopeTemplateBody {
  userId: string
  organizationId: string
  scopeTemplate: Array<AuthScope>
  selectableScopes: Array<AuthScope>
  userIsApi?: boolean
}

export interface UserCategoryTemplateBody {
  userId: string
  organizationId: string
  categoryTemplate: Array<string>
  selectableCategories: Array<string>
}

export interface FirebaseAuthToken {
  iss: string,
  aud: string,
  auth_time: EpochTimeStamp,
  sub: string,
  iat: EpochTimeStamp,
  exp: EpochTimeStamp,
  email: string,
  email_verified: string,
  firebase: FirebaseTokenInfo,
  sign_in_provider: string
}

export interface ScopeTemplate {
  organization: string,
  supplier: string,
  supplierScopeSelection: ScopeSelectionObject
}

export interface ListObjectResponse {
  objects: Array<string>
}

export interface CheckScopeResponse {
  allowed: boolean
  resolution: string
}

export interface RelationshipReadContents {
    key: RelationshipTuple
    timestamp: string
}

export interface RelationshipTuple {
  user: string
  relation: string
  object: string
}

export interface AuthScopeOption {
  label: string;
  value: AuthScope;
}

export interface TokenBody {
  /** User's database id */
  id: string
  /** User's email */
  email?: string
  /** User firstname + lastname */
  fullName: string
  /** Organization the user belongs to */
  organization: string
  /** Organization(s) the user is logged in to */
  organizations: Array<string>
  /** SuperUser yes/no */
  isAdmin: boolean
  /** Supplier user yes/no */
  isSupplier: boolean
  firebaseToken: string
  domain: DomainType
  impersonatedBy?: string
}

export interface TokenResponse {
  success: boolean,
  token: string,
  message: string
}

export interface RelationshipRequest {
  user: string
  relation: string
  object: string
}

export interface RelationshipReadRequest {
  user: string
  /** object is the organization */
  object: string
}

export interface UserRoleRequest {
  organization: string
  relation: string
}


export interface AssignableUserRequest {
  organization: string
}

export interface UserCategoriesRequest {
  userId: string
}

export interface ListObjectRequest {
  user: string
  relation: string
  type: string
}

export interface CreateKeyResponse {
  success: boolean
  message: string
}

export const enum FirebaseAuthError {
  USER_NOT_FOUND = "auth/user-not-found",
  INVALID_CREDENTIAL = "auth/invalid-credential",
  USER_DISABLED = "auth/user-disabled"
}

const datePlus30Days = dayjs().add(30, "day").toDate()

export const useAuthStore = defineStore("auth", () => {
  /** The authorization token */
  const token = useCookie<string | undefined>("sam_token", { expires: datePlus30Days })
  const decodedToken = computed<TokenBody | undefined>(() => token.value ? jwtDecode<TokenBody>(token.value) : undefined)

  const firebaseToken = computed<string | undefined>(() => decodedToken.value?.firebaseToken)
  const decodedFirebaseToken = computed<FirebaseAuthToken | undefined>(() => {
    if (!firebaseToken.value) return undefined
    return jwtDecode<FirebaseAuthToken>(firebaseToken.value)
  })

  /** The scopes the user has access to, example:
   *
   * {
   *   "organizationId": ["CAN_VIEW", "CAN_UPDATE"]
   * }
   */
  const scopes = ref<Record<string, Array<AuthScope>>>({})
  const $i18n = useI18n()

  const pending = ref(false)
  const ssoLoginPending = ref(false)
  const error = ref("")
  const originalToken = useSessionStorage("originalToken", "")

  const currentOrganization = computed<string | undefined>(() => organizations.value?.[0])

  const expiresAt = computed<number>(() => decodedFirebaseToken.value?.exp ?? 0)

  const isSuperAdmin = computed<boolean>(() => decodedToken.value?.isAdmin || false)
  const isSupplier = computed<boolean>(() => decodedToken.value?.isSupplier || false)
  const isImpersonated = computed<boolean>(() => !!decodedToken.value?.impersonatedBy)
  const organizations = computed<Array<string>>(() => decodedToken.value?.organizations || [])
  const hasMultipleOrganizations = computed<boolean>(() => organizations.value.length > 1)

  /** The organization the user is from */
  const sourceOrganization = computed<string | undefined>(() => decodedToken.value?.organization)
  const isSuperAdminOnSupplier = computed<boolean>(() => isSuperAdmin.value && hasMultipleOrganizations.value && organizations.value[0] === currentOrganization.value)

  const renewFirebaseToken = async (user: User | undefined | null) => {
    if (!user) return token.value

    // If the token is pending, wait for it to resolve.
    if (pending.value) {
      await new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          if (!pending.value) {
            clearInterval(interval)
            resolve()
          }
        }, 100)

        setTimeout(() => {
          clearInterval(interval)
          resolve()
        }, 5000)
      })
    }

    pending.value = true

    // If the token is not present, force logout.
    if (!decodedToken.value) return logout()

    const nowEpochInSeconds = new Date().getTime() / 1000
    const isTokenExpired = expiresAt.value < nowEpochInSeconds
    const encodedRefreshedFirebaseToken = await user.getIdToken(isTokenExpired)

    // if the token does not need to be refreshed, return the original token.
    if (firebaseToken.value === encodedRefreshedFirebaseToken) {
      pending.value = false
      return token.value
    }

    console.warn("refreshing token")

    try {
      const { data } = await useSamApi<TokenResponse>("/auth/refreshToken", {
        method: "POST",
        body: JSON.stringify({ firebaseToken: encodedRefreshedFirebaseToken }),
      }, { forceNoRenew: true })

      // If the token is invalid, handle it.
      if (!data.success) {
        console.error("token refresh failed")
        return token.value
      }

      console.warn("token refreshed")

      token.value = data.token
      return data.token
    } catch (e) {
      console.error(e)
      return token.value
    } finally {
      pending.value = false
    }
  }

  const increaseLoginAttempts = async (email: string) => {
    await useSamApi("/auth/update-last-login-attempt", {
      method: "POST",
      body: JSON.stringify({ email: email }),
    }, { forceNoRenew: true })
  }

  const clearLoginAttempts = async (email: string) => {
    await useSamApi("/auth/clear-login-attempts", {
      method: "POST",
      body: JSON.stringify({ email: email }),
    }, { forceNoRenew: true })
  }

  const disableFirebaseUser = async ( email: string, disable: boolean) => {
    return await useSamApi("/auth/disable-firebase-user", {
      method: "POST",
      body: JSON.stringify({
        email,
        disable
      })
    })
  }

  const login = async (firebaseAuth: Auth, rawEmail: string, password: string) => {
    error.value = ""

    if (!rawEmail || !password) {
      error.value = $i18n.t("loginCard.userNotFound")
      throw new Error(error.value)
    }

    pending.value = true
    const isEmail = rawEmail.includes("@")

    const email = (isEmail ? rawEmail : `${rawEmail}@null.null`).toLowerCase()

    if (!isEmail) {
      Sentry.captureMessage("Tried logging in with invalid email (possibly username)", {
        level: "info",
        user: {
          email: rawEmail,
        },
      })
    }

    try {
      const userCredential = await signInWithEmailAndPassword(firebaseAuth, email, password)

      // Signed in
      const firebaseToken = await userCredential.user.getIdToken()

      const { data } = await useSamApi<{ token: string; message: string }>("/auth/login", {
        method: "POST",
        body: JSON.stringify({ firebaseToken }),
      }, { forceNoRenew: true })

      if (!data.token) {
        throw new Error(data.message)
      }

      resetStoreModules()

      token.value = data.token
    } catch (e) {
      const code = typeof e === 'object' && e !== null && 'code' in e ? e.code : undefined

      if (code === FirebaseAuthError.INVALID_CREDENTIAL || code === FirebaseAuthError.USER_NOT_FOUND) {
        error.value = $i18n.t("loginCard.invalidLogin")
      } else if (code === FirebaseAuthError.USER_DISABLED) {
        error.value = $i18n.t("loginCard.userDisabled")
      } else {
        error.value = getErrorMessage(e)
      }

      await increaseLoginAttempts(email)
      throw new Error(getErrorMessage(e) || $i18n.t("error"))
    } finally {
      pending.value = false
    }
  }

  const loginWith = async (firebaseAuth: Auth, providerId: ProviderId ) => {
    error.value = ""
    ssoLoginPending.value = true

    try {
      const provider = new OAuthProvider(providerId)

      const userCredential = await signInWithPopup(firebaseAuth, provider)

      // Signed in
      const firebaseToken = await userCredential.user.getIdToken()

      const { data } = await useSamApi<{ success: boolean, message: string, token?: string, redirectToSsoTab?: boolean }>("/auth/login", {
        method: "POST",
        body: JSON.stringify({ firebaseToken, providerId }),
      }, { forceNoRenew: true })

      if (!data.success) {
        throw new Error(data.message)
      }

      resetStoreModules()

      token.value = data.token

      return data
    } catch (e) {
      const code = typeof e === 'object' && e !== null && 'code' in e ? e.code : undefined

      if (code === FirebaseAuthError.INVALID_CREDENTIAL || code === FirebaseAuthError.USER_NOT_FOUND) {
        error.value = $i18n.t("loginCard.invalidLogin")
      } else if (code === FirebaseAuthError.USER_DISABLED) {
        error.value = $i18n.t("loginCard.userDisabled")
      } else {
        error.value = getErrorMessage(e)
      }
    }
    finally {
      ssoLoginPending.value = false
    }
  }

  const impersonateLogin = async (email: string) => {
    try {
      const { data } = await useSamApi<{ token: string; message: string }>("/auth/impersonate-login", {
        method: "POST",
        body: JSON.stringify({ firebaseToken: firebaseToken.value, email }),
      }, { forceNoRenew: true })

      if (!data.token) {
        throw new Error(data.message)
      }

      const oldToken = token.value

      resetStoreModules()

      token.value = data.token
      originalToken.value = oldToken
    } catch (e) {
      Sentry.captureException(e)
      throw new Error(getErrorMessage(e))
    }
  }

  const exitImpersonate = () => {
    if (!originalToken.value) {
      console.error("No original token found")
      return
    }

    const oldToken = originalToken.value
    originalToken.value = ""

    resetStoreModules()

    token.value = oldToken
  }

  const updateOrganization = async (organization: string) => {
    error.value = ""
    pending.value = true

    try {
      const { data } = await useSamApi<{ token: string }>("/auth/organization", {
        method: "PUT",
        body: JSON.stringify({ firebaseToken: firebaseToken.value, organization }),
      }, { forceNoRenew: true })

      resetStoreModules()

      token.value = data.token

      await fetchAllOrganizationScopes()
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      console.error(errorMessage)
      error.value = errorMessage
    } finally {
      pending.value = false
    }
  }

  const fetchAllOrganizationScopes = async () => {
    for (const loggedInOrganization of organizations.value) {
      await fetchScopes({
        object: loggedInOrganization,
        user: decodedToken.value!.id,
      })
    }
  }

  const logout = (firebaseAuth?: Auth) => {
    if (decodedToken.value) {
      // Doesnt really do anything, just logs the logout on the server
      useSamApi("/auth/logout", {
        method: "POST",
        body: JSON.stringify({ id: decodedToken.value.id }),
      }, { forceNoRenew: true })
    }

    token.value = undefined
    error.value = ""

    firebaseAuth?.signOut()
    Sentry.setUser(null)

    resetStoreModules()

    location.href = "/?logout=true"
  }

  const logoutOrganization = async () => {
    const { data } = await useSamApi<TokenResponse>("/auth/organization/logout", { method: "POST" }, { forceNoRenew: true })

    token.value = data.token
    error.value = ""

    resetStoreModules()
  }

  const fetchScopes = async (scope: RelationshipReadRequest): Promise<Array<AuthScope>> => {
    if (!token.value) return []

    if (decodedToken.value?.isAdmin) {
      // Remove scopes that are not relevant for superadmins
      const filteredScopes = Object.values(AuthScope).filter(scope => ![AuthScope.OWNER, AuthScope.CAN_VIEW].includes(scope))
      scopes.value[scope.object] = filteredScopes

      return filteredScopes
    }

    const relationshipsResponse = await useCrudMethodsAuth<RelationshipReadRequest, Array<RelationshipReadContents>>("/openfga/read-all-relationships").postItem(scope)
    const data = relationshipsResponse.map(scopeContent => scopeContent.key.relation as AuthScope)

    scopes.value[scope.object] = data
    return data
  }

  const sendPasswordResetLink = async (firebaseAuth: Auth, email: string): Promise<boolean> => {
    return sendPasswordResetEmail(firebaseAuth, email).then(() => true).catch(() => false)
  }

  const resetFirebasePassword = async (firebaseAuth: Auth, actionCode: string, password: string): Promise<boolean> => {
    try {
      await verifyPasswordResetCode(firebaseAuth, actionCode)
      await confirmPasswordReset(firebaseAuth, actionCode, password)
      return true
    } catch {
      return false
    }
  }

  const fetchManagedUserScopes = async (scope: RelationshipReadRequest): Promise<Array<AuthScope>> => {
    return await useCrudMethodsAuth<RelationshipReadRequest,Array<RelationshipReadContents>>("/openfga/read-all-relationships").postItem(scope)
    .then(response => response.map(scopeContent => scopeContent.key.relation as AuthScope))
  }

  const fetchAllManagedUserScopes = async (userId: string): Promise<Array<RelationshipReadContents>> => {
    return await useCrudMethodsAuth<RelationshipReadRequest,Array<RelationshipReadContents>>("/openfga/read-all-relationships").postItem({ user: userId })
  }

  const fetchManagedUserTuples = async (scope: RelationshipReadRequest): Promise<Array<RelationshipTuple>> => {
    return await useCrudMethodsAuth<RelationshipReadRequest,Array<RelationshipReadContents>>("/openfga/read-all-relationships").postItem(scope)
    .then(response => response.map(scopeContent => scopeContent.key as RelationshipTuple))
  }

  const fetchUsersWithRelation = async (supplierId: string, relation: string): Promise<Array<string>> => {
    return await useCrudMethodsAuth<UserRoleRequest,Array<string>>("/openfga/read-all-users").postItem({ organization: supplierId, relation: relation })
  }

  const fetchCategories = async (scope: UserCategoriesRequest): Promise<Array<string>> => {
    const resp = useCrudMethodsAuth<UserCategoriesRequest,Array<string>>("/openfga/list-all-categories").postItem(scope)
    return resp as Promise<Array<string>>
  }

  const fetchCategoryTuples = async (scope: {user: string}): Promise<ListObjectResponse> => {
    return await useCrudMethodsAuth<{user:string},ListObjectResponse>("/openfga/list-categories").postItem(scope)
  }

  const fetchUserCategoriesOfOrganization = async (user: string, organization: string): Promise<Array<string>> => {
    return await useCrudMethodsAuth<{user:string, organization: string},Array<string>>("/openfga/list-user-categories-of-organization").postItem({
      user,
      organization
    })
  }

  const applyScopeTemplate = async (userId: string, organizationId: string, scopeTemplate: Array<AuthScope>,selectableScopes: Array<AuthScope>, userIsApi?: boolean) => {
    return await useCrudMethodsAuth<UserScopeTemplateBody, string>("/openfga/apply-template").postItem({ userId, organizationId, scopeTemplate, selectableScopes, ...(userIsApi && { userIsApi }) })
  }

  const applyAllSupplierTemplates = async (organizationId: string) => {
    return await useCrudMethodsAuth<unknown, undefined>(`/scope-templates/${organizationId}/apply-all-templates`).postItem({})
  }

  const applyCategoryTemplate = async (userId: string, organizationId: string, categoryTemplate: Array<string>, selectableCategories: Array<string>, userIsApi?: boolean) => {
    return await useCrudMethodsAuth<UserCategoryTemplateBody, string>("/openfga/apply-category-template").postItem({ userId, organizationId, categoryTemplate, selectableCategories, ...(userIsApi && { userIsApi }) })
  }

  const updateAndApplySupplierTemplate = async (organizationId: string, supplierId: string, selectedScopes: ScopeSelectionObject ) => {
    return await useCrudMethodsAuth<ScopeSelectionObject, ScopeTemplate>(`/scope-templates/${organizationId}/update-and-apply-supplier-template/${supplierId}`).postItem(selectedScopes)
  }

  /** User must have this scope for the requested organization */
  const hasScope = (organization: string, scope: AuthScope) => isSuperAdmin.value || scopes.value![organization]?.includes(scope)

  /** User must have these scopes for the requested organization */
  const hasScopes = (organization: string, requiredScopes: Array<AuthScope>) => isSuperAdmin.value || requiredScopes.every(scope => scopes.value![organization]?.includes(scope))

  /** User must have at least one of these scopes for the requested organization */
  const hasAnyScope = (organization: string, conditionalScopes: Array<AuthScope>) => isSuperAdmin.value || scopes.value![organization]?.some(scope => conditionalScopes.includes(scope))

  /** User must have at least one organization with the scope */
  const hasOrganizationWithScope = (scope: AuthScope) => isSuperAdmin.value || organizations.value.some(organization => hasScope(organization, scope))

  /** User must have at least one organization which contains all the scopes */
  const hasOrganizationWithScopes = (requiredScopes: Array<AuthScope>) => isSuperAdmin.value || organizations.value.some(organization => hasScopes(organization, requiredScopes))

  /** User must have at least one organization with one of these scopes */
  const hasOrganizationWithAnyScope = (conditionalScopes: Array<AuthScope>) => isSuperAdmin.value || organizations.value.some(organization => hasAnyScope(organization, conditionalScopes))

  const hasOriginalToken = computed<boolean>(() => !!originalToken.value)

  const checkScope = async (user: string, scope: AuthScope, organization: string): Promise<CheckScopeResponse> => {
    const tuple = {
      user: user,
      relation: scope.toLowerCase(),
      object: organization
    }
    const resp = await useCrudMethodsAuth("/openfga/check-relationship").postItem(tuple)
    return resp as Promise<CheckScopeResponse>
  }

  const setScopes = (scopes: Array<RelationshipRequest>): Promise<string> => {
    if (scopes.length === 0) return Promise.resolve("")
    return useCrudMethodsAuth<unknown,string>("/openfga/create-relationships").postItem({ tuples: scopes, type: "scope" })
  }

  const setCategories = (categories: Array<RelationshipRequest>): Promise<string> => {
    if (categories.length === 0) return Promise.resolve("")
    return useCrudMethodsAuth<unknown,string>("/openfga/create-relationships").postItem({ tuples: categories, type: "category" })
  }

  const listObjects = async (scope: ListObjectRequest): Promise<ListObjectResponse> => {
    return await useCrudMethodsAuth<ListObjectRequest, ListObjectResponse>("/openfga/list-objects").postItem(scope)
  }

  const createNewApiKey = async (organization: string, name: string, issuedTo?: string ): Promise<CreateKeyResponse>  => {
    return await useCrudMethodsAuth<unknown, CreateKeyResponse>("/auth/create-new-api-key").postItem({
      organization,
      name,
      ...(issuedTo && { issuedTo }),
      createKey: true
    })
  }

  const replaceApiKey = async (apiKeyId: string): Promise<string> => {
    return await useCrudMethodsAuth<unknown, string>("/auth/replace-api-key").postItem({
      apiKeyId,
    })
  }

  const createFirebaseUser = async (email: string): Promise<UserRecord> => {
    return await useCrudMethodsAuth<unknown, UserRecord>("/auth/create-firebase-user").postItem({
      email,
    })
  }

  const giveOrganizationsLoginRights = (userId: string) => {
    return useCrudMethodsAuth("/openfga/give-access-to-all-organizations").postItem({
      userId,
    })
  }

  return {
    pending: readonly(pending),
    ssoLoginPending: readonly(ssoLoginPending),
    error: readonly(error),
    token,
    decodedToken,
    expiresAt,
    organizations,
    /**
     * The organization the user is logged into
     */
    currentOrganization,
    scopes,
    isSuperAdmin,
    isSuperAdminOnSupplier,
    isImpersonated,
    isSupplier,
    /**
     * The organization the user from
     */
    sourceOrganization,
    hasMultipleOrganizations,
    /** When impersonating a user this value is true, as long as the users session is active */
    hasOriginalToken,

    login,
    loginWith,
    impersonateLogin,
    exitImpersonate,
    logout,
    logoutOrganization,
    updateOrganization,
    /** User must have this scope for the requested organization */
    hasScope,
    /** User must have these scopes for the requested organization */
    hasScopes,
    /** User must have at least one of these scopes for the requested organization */
    hasAnyScope,
    /** User must have at least one organization with the scope */
    hasOrganizationWithScope,
    /** User must have at least one organization which contains all the scopes */
    hasOrganizationWithScopes,
    /** User must have at least one organization with one of these scopes */
    hasOrganizationWithAnyScope,
    checkScope,
    setScopes,
    resetFirebasePassword,
    sendPasswordResetLink,
    fetchAllOrganizationScopes,
    fetchScopes,
    fetchUsersWithRelation,
    fetchManagedUserScopes,
    increaseLoginAttempts,
    clearLoginAttempts,
    fetchManagedUserTuples,
    renewFirebaseToken,
    setCategories,
    fetchCategories,
    fetchCategoryTuples,
    fetchUserCategoriesOfOrganization,
    listObjects,
    applyScopeTemplate,
    applyCategoryTemplate,
    updateAndApplySupplierTemplate,
    fetchAllManagedUserScopes,
    disableFirebaseUser,
    createNewApiKey,
    replaceApiKey,
    createFirebaseUser,
    giveOrganizationsLoginRights,
    applyAllSupplierTemplates
  }
})
