import isObject from "lodash-es/isObject"
import isEmpty from "lodash-es/isEmpty"
import type { Pinia } from "pinia"

export function resetStoreModules(pinia?: Pinia) {
  // https://pinia.vuejs.org/ssr/#using-the-store-outside-of-setup
  useActionStore(pinia).$dispose()
  useAssetStore(pinia).$dispose()
  useBudgetSettingsStore(pinia).$dispose()
  useContractStore(pinia).$dispose()
  useFileStore(pinia).$dispose()
  useInspectionStore(pinia).$dispose()
  useStreetStore(pinia).$dispose()
  useCityStore(pinia).$dispose()
  useDistrictStore(pinia).$dispose()
  useAreaStore(pinia).$dispose()
  useMunicipalityStore(pinia).$dispose()
  useArticleCategoryStore(pinia).$dispose()
  useMalfunctionDescriptionStore(pinia).$dispose()
  useInspectionDescriptionStore(pinia).$dispose()
  useActionDescriptionStore(pinia).$dispose()
  useOrganizationStore(pinia).$dispose()
  useStatusStore(pinia).$dispose()
  useUserStore(pinia).$dispose()
  useMalfunctionStore(pinia).$dispose()
  useSettingsStore(pinia).$dispose()

  modulesPerOrganization.value = {}
}

export const getUrlSearchParams = <T = Record<string, unknown>>(queryParameters?: T): URLSearchParams => {
  const query = new URLSearchParams()

  if (!queryParameters) return query

  for (const [key, value] of Object.entries(queryParameters)) {
    if (value === undefined || value === null) continue

    if (isObject(value)) {
      if (!isEmpty(value))
        query.append(
          key,
          JSON.stringify(value, (k, v) => v ?? undefined)
        )
    } else {
      query.append(key, value.toString())
    }
  }

  return query
}
