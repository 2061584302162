import type { AuthScope} from "./auth.store"
import { actionStatusScopes, inspectionStatusScopes, malfunctionStatusScopes } from "./auth.store"
import type { Ticket } from "./ticket.store"
import { TicketType } from "./ticket.store"

export enum StatusGroup {
  OPERATIONAL = "OPERATIONAL",
  QUOTATION = "QUOTATION",
  FINANCIAL = "FINANCIAL",
}

export enum StatusDescription {
  /** OPERATIONAL status */
  NEW = "NEW",

  /** QUOTATION status */
  QUOTATION = "QUOTATION",
  QUOTE_AWAIT_APPROVAL = "QUOTE_AWAIT_APPROVAL",
  QUOTE_ACCEPTED = "QUOTE_ACCEPTED",
  QUOTE_REJECTED = "QUOTE_REJECTED",
  IN_ORDER = "IN_ORDER",

  /** OPERATIONAL status */
  PROCESSING = "PROCESSING",
  DID_NOT_PROCESS = "DID_NOT_PROCESS",
  PROCESSED = "PROCESSED",

  /** FINANCIAL status */
  AWAIT_ACCEPTANCE = "AWAIT_ACCEPTANCE",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  INVOICED = "INVOICED",

  /** OPERATIONAL status */
  DONE = "DONE",
}

export interface TicketStatus {
  _id: string
  description: StatusDescription
  sortOrder: number
  group: StatusGroup
}

export const useStatusStore = defineStore("status", () => {
  const authStore = useAuthStore()
  const { scopes } = storeToRefs(authStore)

  /** @deprecated */
  const statuses = ref<Array<TicketStatus>>([])
  /** @deprecated */
  const filteredStatuses = ref<Array<TicketStatus>>([])

  const selectableStatuses = computed(() => {
    return filteredStatuses.value
  })

  const getStatuses = async () => {
    const { data } = await useSamApi<Array<TicketStatus>>("/ticket-states")
    statuses.value = data
    return data
  }

  const generateStatusDescriptionMapping = (ticketType: TicketType): Record<string, StatusDescription> => {
    const prefix = `CAN_CHANGE_${ticketType.toUpperCase()}_STATUS_`
    const statusDescriptionMapping: Record<string, StatusDescription> = {}

    for (const key in StatusDescription) {
      if (StatusDescription.hasOwnProperty(key)) {
        statusDescriptionMapping[`${prefix}${key}`] = StatusDescription[key as keyof typeof StatusDescription]
      }
    }

    return statusDescriptionMapping
  }

  const getSelectableStatuses = async (ticket: Ticket | undefined | null): Promise<Array<TicketStatus>> => {
    if (!ticket) { return [] }

    const statusEnum = ticket.type === TicketType.ACTION
      ? actionStatusScopes
      : ticket.type === TicketType.INSPECTION
        ? inspectionStatusScopes
        : malfunctionStatusScopes

    // Map status enum to status description
    const statusDescriptionMapping = generateStatusDescriptionMapping(ticket.type)

    // Filter out statuses that are not in the user's scope, then map them against the status description
    const acceptedScopes = scopes.value[ticket.organization].filter(scope => statusEnum.includes(scope))
    const selectableStatuses = Object.keys(statusDescriptionMapping)
      .filter(scope => acceptedScopes.includes(scope as AuthScope))
      .map(scope => statusDescriptionMapping[scope]) as Array<StatusDescription>

    // Fetch statuses and filter out statuses that are not in the selectable statuses
    filteredStatuses.value = (await getStatuses()).filter(status => selectableStatuses.includes(status.description))
    return filteredStatuses.value
  }

  return {
    statuses,
    selectableStatuses,

    getStatuses,
    getSelectableStatuses
  }
})