export interface BudgetCorrectionSetting {
  GOOD?: number
  DECENT?: number
  INSUFFICIENT?: number
  BAD?: number
}

export interface BudgetComponentIntervalSetting {
  component: string
  interval?: number
}

export interface BudgetCategorySetting {
  assetCategory: string
  components: Array<BudgetComponentIntervalSetting>
}

export interface BudgetSetting {
  _id: string
  organization?: string
  indexPercentage?: number
  defaultInterval?: number
  correction?: BudgetCorrectionSetting
  components?: Array<BudgetComponentIntervalSetting>
  categories?: Array<BudgetCategorySetting>
  calculationYearsPeriod?: number
}

export const useBudgetSettingsStore = defineStore("budget-settings", () => {
  /** @deprecated */
  const budgetSettings = ref<Array<BudgetSetting>>([])

  const getBudgetSettings = async () => {
    return await useCrudMethods<BudgetSetting>("/budget-settings", budgetSettings).readItems()
  }

  const getBudgetSettingById = async (id: string) => {
    return await useCrudMethods<BudgetSetting>("/budget-settings", budgetSettings).readItemById(id)
  }

  const updateBudgetSetting = async (id: string, payload: Partial<BudgetSetting>) => {
    return await useCrudMethods<BudgetSetting>("/budget-settings", budgetSettings).updateItem(id, payload)
  }

  return {
    budgetSettings,
    getBudgetSettings,
    getBudgetSettingById,
    updateBudgetSetting,
  }
})
