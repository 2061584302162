import isEmpty from "lodash-es/isEmpty"
import dayjs from "dayjs"
import * as Sentry from "@sentry/vue"
import type { AuthScope, AssignableUserRequest } from "./auth.store"
import type { Organization } from "./organization.store"
import type { PaginateResult } from "mongoose"
import type { UserRecord } from "firebase-admin/auth"
import type { HistoryDataPoint } from "./types"

export enum UserRole {
  /** A supplier mechanic may have access to multiple organizations, but with set permissions from the organization it logs into */
  SUPPLIER_MECHANIC = "SUPPLIER_MECHANIC",
  /** A supplier planner may have access to multiple organizations, but with set permissions from the organization it logs into */
  SUPPLIER_PLANNER = "SUPPLIER_PLANNER",
  /** A supplier manager may have access to multiple organizations, but with set permissions from the organization it logs into */
  SUPPLIER_MANAGER = "SUPPLIER_MANAGER",
  //** Super user account */
  SUPER_ADMIN = "SUPER_ADMIN",
  //** Default - Normal user account */
  USER = "USER",
}

export enum InfoCardType {
  ASSETS = "assets",
  TICKETS = "tickets",
  MALFUNCTIONS = "malfunctions",
  ACTIONS = "actions",
  INSPECTIONS = "inspections",
  RISKY_ASSETS = "risky-assets",
  FINANCES = "finances",
}

export type SupplierRole = UserRole.SUPPLIER_MECHANIC | UserRole.SUPPLIER_PLANNER | UserRole.SUPPLIER_MANAGER

export interface AllowedStatuses {
  actions: Array<AuthScope>
  inspections: Array<AuthScope>
  malfunctions: Array<AuthScope>
}

export interface OrganizationLogin {
  organization: Organization["_id"]
  date: string
}

export interface User {
  _id: string
  /** @deprecated Email is used for logging in */
  username?: string
  userRole: UserRole
  email?: string
  firstName?: string
  lastName?: string
  phone?: string
  hasAccess: boolean
  organization: string
  lastLogins: Array<OrganizationLogin>
  lastReleaseNoteRead?: string
  lastLoginAttempts?: Array<Date>
}

export interface LookupUser extends LookupItem {
  email?: string
  hasAccess: boolean
  userRole: string
}

/** Map visibility on pages */
export enum MapVisibility {
  ASSETS = "ASSETS",
  ASSET_DETAILS = "ASSET_DETAILS",
  TICKETS = "TICKETS",
}

export enum CookiePreferences {
  FUNCTIONAL = 'FUNCTIONAL',
  ANALYTICS = 'ANALYTICS',
  TRACKING = 'TRACKING',
}

export const useUserStore = defineStore("user", () => {
  const currentUser = ref<User>()
  /** @deprecated */
  const users = ref<Array<User>>([])

  const getUsers = (pagination = true, organization?: string) => useCrudMethods<User>("/users", users).readItems(organization ? { pagination, organization } : { pagination })
  const getUsersByPage = async (queryParameters: Record<string, unknown>) => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<PaginateResult<User>>(`/users?${query}`)
    return data
  }
  const getUserById = (id: User["_id"]) => useCrudMethods<User>("/users", users).readItemById(id)
  const updateUser = (id: User["_id"], user: Partial<User>) => useCrudMethods<User>("/users", users).updateItem(id, user)
  const createUser = (user: Partial<User>) => useCrudMethods<User>("/users", users).createItem(user)
  const deleteUser = (id: User["_id"]) => useCrudMethods<User>("/users", users).deleteItem(id)

  const updateUserRole = async (id: User["_id"], userRole: UserRole) => {
    await useSamApi(`/users/${id}/role`, { method: "PUT", body: JSON.stringify({ userRole }) })
    return await getUserById(id)
  }

  const updatePassword = async (oldPassword: string, newPassword: string) => {
    const { responseStatus, data, response } = await useSamApi<{ message: string }>("/users/password", {
      method: "POST",
      body: JSON.stringify({ oldPassword, newPassword }),
    })
    return { response, data, responseStatus }
  }

  const updateReadReleaseNoteDate = async () => {
    await useSamApi(`/users/read-release-note`, { method: "POST" })
    currentUser.value!.lastReleaseNoteRead = dayjs().toISOString()
  }

  const getCurrentUser = async () => {
    const { data } = await useSamApi<User>("/users/info")
    currentUser.value = data

    Sentry.setUser({
      id: data._id,
      email: data.email,
    })

    return data
  }

  const getUsersLookup = async (organization?: string) => {
    const searchParams = new URLSearchParams({ organization: organization || "" })

    const { data } = await useSamApi<Array<LookupUser>>(`/users/lookup${organization ? `?${searchParams}` : ""}`)
    return data
  }

  const getAssignableUsersLookup = async (organization: string) => {
    const searchParams = new URLSearchParams({ organization: organization})
    const assignableUserIdList = await useCrudMethodsAuth<AssignableUserRequest, Array<string>>("/users/read-all-assignable-users").postItem({
      organization: organization,
    })
    const { data } = await useSamApi<Array<LookupUser>>(`/users/lookup${organization ? `?${searchParams}` : ""}`)
    const filteredUserList = data.flatMap((user) =>
    assignableUserIdList.includes(user._id) ? user : []
    )

    return filteredUserList
  }

  const getUserCount = async (organization?: string, createdFrom?: Date) => {
    const searchParams = new URLSearchParams()
    if (organization) { searchParams.append("organization", organization) }
    if (createdFrom) { searchParams.append("createdFrom", createdFrom.toISOString()) }

    const { data } = await useSamApi<{ count: number }>(`/users/count?${searchParams}`)
    return data.count
  }

  const getAuthenticationUser = async (userId: string) => {
    const { data } = await useSamApi<UserRecord>(`/users/${userId}/authentication`)
    return isEmpty(data) ? null : data
  }

  const updateCurrentUserPhone = async (phone: string) => {
    const { data } = await useSamApi<User>("/users/phone", { method: "PUT", body: JSON.stringify({ phone }) })
    currentUser.value!.phone = data.phone
    return data
  }

  const updateUserEmail = async( userId: string, email: string) => {
    const { data } = await useSamApi<User>(`/users/${userId}/update-email`, { method: "PUT", body: JSON.stringify({ email })})
    return data
  }

  const getUserHistoryCount = async (organization?: string, query?: Record<string, unknown>) => {
    const queryParameters = getUrlSearchParams({ organization, ...query })
    const { data } = await useSamApi<Array<HistoryDataPoint>>(`/users/history-count?${queryParameters}`)

    return data
  }

  return {
    currentUser,
    users,

    getCurrentUser,
    getUserById,
    getUsers,
    getUsersByPage,
    updateUser,
    updateUserEmail,
    createUser,
    deleteUser,
    getUserCount,
    getUsersLookup,
    getAssignableUsersLookup,

    updateReadReleaseNoteDate,
    updatePassword,
    updateUserRole,
    updateCurrentUserPhone,

    getAuthenticationUser,

    getUserHistoryCount,
  }
})
