import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import main_45global from "/app/middleware/main.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  main_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/app/middleware/admin.ts"),
  organization: () => import("/app/middleware/organization.ts")
}